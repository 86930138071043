<template>
  <section id="dashboard">
      <div class="title">
          <div class="welcome">Welcome</div>
          <div class="date">
              <p>{{currentDate}}</p>
              <p>{{currentTime}}</p>
          </div>
      </div>
      <div class="wrapper">
          <div class="left">
              <div class="items">
                  <div class="item post">
                      <div class="img">
                          <img src="../assets/icon/signs-post-solid.svg" alt="">
                      </div>
                      <div class="count" v-if="postsCount">
                          <h3>{{postsCount}}</h3>
                          <p>Posts</p>
                      </div>
                  </div>
                  <div class="item announcement">
                      <div class="img"><img src="../assets/icon/bell-solid.svg" alt=""></div>
                      <div class="count" v-if="annCount">
                          <h3>{{annCount}}</h3>
                          <p>Announcements</p>
                      </div>
                  </div>
                  <div class="item event">
                      <div class="img"><img src="../assets/icon/calendar-solid.svg" alt=""></div>
                      <div class="count" v-if="eventsCount">
                          <h3>{{eventsCount}}</h3>
                          <p>Events</p>
                      </div>
                  </div>
              </div>

              <div class="popular-posts">
                  <h3>Popular Posts</h3>
              </div>
          </div>
          <div class="right">
              <div class="calendar"></div>
              
          </div>
      </div>
  </section>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore } from '../firebase/config'
export default {
    name:'Dashboard',
    setup(){
        const currentDate = ref(null)
        const currentTime = ref(null)
        const postsCount = ref(null)
        const annCount = ref(null)
        const eventsCount = ref(null)
        currentDate.value = new Date().toLocaleString('en-us',{dateStyle:'full'})
        currentTime.value = new Date().toLocaleTimeString('en-us',{timeStyle:'medium'})

        projectFirestore.collection("blogPosts").get().then(snap=>{
        postsCount.value =  snap.size
      })
      projectFirestore.collection("announcements").get().then(snap=>{
        annCount.value =  snap.size
      })
      projectFirestore.collection("events").get().then(snap=>{
        eventsCount.value =  snap.size
      })

        return {currentDate,currentTime,postsCount,annCount,eventsCount}
    }
 
}
</script>

<style scoped>

#dashboard .title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;

}
#dashboard .title .welcome{
    font-size: 1.5rem;
}
/* #dashboard .wrapper .left{
    width: 70%;
} */
#dashboard .wrapper .left .items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
#dashboard .wrapper .left .items .item{
    display: flex;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    align-items: center;    
    background: #f4f4f4;
    width: 100%; 
    transition: .5s ease all;   
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
}
#dashboard .wrapper .left .items .item:hover{
    transform: rotateZ(-1deg) scale(1.01);
    
}
#dashboard .wrapper .left .items .item .img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#dashboard .wrapper .left .items .item img{
    width: 20px;
    filter: invert(90%);
    
}
#dashboard .wrapper .left .items .item .count{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#dashboard .wrapper .left .post .img{
    background: var(--primary);
}
#dashboard .wrapper .left .announcement .img{
    background: var(--danger);
}
#dashboard .wrapper .left .event .img{
    background: var(--secondary);
}


/* Popular Posts */
#dashboard .wrapper .popular-posts{
    margin: 1rem 0;
    position: relative;
    padding: 0.5rem 0;
}
#dashboard .wrapper .popular-posts h3::after{
    content:"";
    width:200px;
    position: absolute;
    left: 0;
    bottom: 0;
    
    border-bottom: 1px solid var(--secondary);
}
@media (min-width:768px) {
    #dashboard .wrapper .left .items{
        flex-direction: row;
    }
     #dashboard .wrapper .left .items .item{
         width: 30%;
     }
}
</style>