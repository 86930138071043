<template>  
      <section id="events">
          <div class="event" v-if="event">
        <div>
          <p>{{event.title}}</p>          
          <p>
              <span>{{new Date(event.date).toLocaleString('en-us',{dateStyle:'medium'})}}</span>             
          </p>
      </div>
      <div class="update">
          <img @click="handleDelete" src="../assets/icon/trash-regular.svg" alt="">       
      </div>
    </div>  </section>    

</template>

<script>
import useDocument from '../composables/useDocument'

export default {
name:"ShowEvents",
props:['event'],
setup(props){
    
    const {deleteDoc} = useDocument('events', props.event.id)

    const handleDelete = async()=>{
        await deleteDoc()
    }

    return {handleDelete}
}

}
</script>

<style scoped>
#events{
    display: inline-grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
}
 .event {
    width: 380px;
    padding: 0.2rem 2rem;
    display: flex;  
    justify-content: space-between;
    align-items: center;    
    background: #303030;
    margin: 0.5rem 0rem;
    border-radius: 10px;
    color: aliceblue;
}
.event img{
    width: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    filter: invert(100%);
    cursor: pointer;
    padding: 0.4rem 0;

}

</style>