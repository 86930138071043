<template>
  <section id="admin">    
      <div class="navigation"   v-if="showValue">
      <AdminNavigation/>
    </div>
   
    <div class="admin-side">
      <div class="head">
        <AdminHeader  @showBar="showBar"/>
      </div>
      <div class="body">
        <div class="container">
          <div v-if="error">
          {{error}}</div>
          <div v-if="ann" class="post">
            <h3>{{ann.annTitle}}</h3>    
            <p class="pre" v-html="ann.annHTML"></p>
        </div>
 
          <div v-else><Spinner/></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AdminNavigation from "../components/AdminNavigation.vue";
import AdminHeader from "../components/AdminHeader.vue";
import { ref } from '@vue/reactivity';
import getAnn from "../composables/getAnn"
import Spinner from "../components/Spinner.vue"
export default {
  name:'ViewAnnouncement',
    props: ["id"],
    components: { AdminNavigation, AdminHeader,Spinner },
    setup(props){
      const showValue = ref(null)
      const { ann, error } = getAnn(props.id)

      const showBar = (show)=>{
        showValue.value = show
      }
      return {
        showBar, showValue, ann, error
      }
    }
    
}
</script>

<style scoped>
#admin{
  height: 100vh; 
  display: flex;
}
#admin .navigation{
  flex: 1;
  transition: 0.5s ease all;
}
#admin .admin-side{
  flex: 4;
}
#admin .admin-side .body .container{
  padding-top: 1rem;
}
#admin .admin-side .body .container h3{
  padding: 1rem 0
}
#admin .admin-side .body .container img{
  width: 100%;
  
  padding: 1rem 0;
}
#admin .admin-side .body .container .post .pre :deep(img){
  width: 100%;
  padding: 0.5rem 0;
}
#admin .admin-side .body .container .post .pre :deep(li){
  margin-left: 2rem;
}
</style>