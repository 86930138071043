<template>
  <section class="hero">
      <div class="container">
          <div class="welcome">
              <h1>Welcome to Yangchenphug Higher Secondary School </h1>
              <h2>Perseverance for 
                <div id=flip>
                  <div><div>Excellence</div></div>
                  <div><div>Character</div></div>
                  <div><div>Integrity</div></div>
               </div>
              </h2>
            <p>The fundamental purpose of Yangchenphug is to engender productive citizens possessing national, moral, social and personal values, with academic excellence coupled with other life skills, who would be competitive and useful in contributing towards the attainment of Gross National Happiness through the provision of high quality educational facilities. </p>          
           <router-link :to = "{name: 'VisionMission'}"> <button class="btn btn-primary">
                    Learn More
                </button></router-link>
          </div>
          <div class="hero-image">
              <img src="@/assets/img/HM-66-birthday.jpg" alt="">
          </div>
      </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap');
section.hero{
    min-height: 350px;
    display: flex;     
    align-items: center;
    position: relative;
    background: #f4f4f4;
}
section.hero::before{
  content: "";
  position: absolute;
  width: 50%;
  border-bottom: 350px solid #f4f4f4;
  border-right: 100px solid transparent;
  z-index: 1;
}
section.hero .welcome{
  position: relative;
  z-index: 2;
  width: 100%;
}

section.hero .welcome h1{
    font-family: 'Cookie', cursive;
    color: var(--secondary);
    font-size: 2rem;
    font-weight: 400;
}
section.hero .welcome button{
  margin-bottom: 1rem;
}
section.hero h2{
    font-family: 'Cinzel', serif;
    color: var(--secodary);
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 0.75rem;
    display: flex;   
}

section.hero p{   
    margin-bottom: 1rem;
}

#flip {
  height:50px;
  overflow:hidden;
  margin-left: 1rem;
  text-transform: uppercase;
}

#flip > div > div {
  color:#fff;  
  height:45px;
  margin-bottom:45px;
  display:inline-block; 
}

#flip div:first-child {
  animation: show 10s linear infinite;
}

#flip div div {
  color:var(--primary);
}
#flip div:first-child div {
  color:#10779c;
}
#flip div:last-child div {
  color:#DC143C;
}

@keyframes show {
  0% {margin-top:-270px;}
  5% {margin-top:-180px;}
  33% {margin-top:-180px;}
  38% {margin-top:-90px;}
  66% {margin-top:-90px;}
  71% {margin-top:0px;}
  99.99% {margin-top:0px;}
  100% {margin-top:-270px;}
}
section.hero .hero-image img{
  display: none;
}

@media (min-width:1100px) {
  section.hero .hero-image img{
    display: block;
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    right: 4rem;
    z-index: 0;
}
section.hero .welcome{

    width: 52%;
   
}
section.hero h2{
  
    font-size: 1.8rem;
  }
}

</style>