<template>
  <header>
       <div class="top">
          <div class="container">
              <div class="contact">
                  <img src="@/assets/icon/phone.svg" alt="">
                  <div>
                      <h5>
                          Call Us: +975-2-322366
                      </h5>
                      <h6>yangchenphughss@education.gov.bt</h6>
                  </div>

              </div>
              <div class="branding" @click="showNav = !showNav">
                   <img src="../../assets/icon/bars-regular.svg" alt="">
              </div>
              <div class="time">
                  <img src="@/assets/icon/clock.svg" alt="">
                  <div>
                      <h5>Working Hours</h5>
                      <h6>Mon-Sat(8AM-4PM)</h6>
                  </div>
              </div>
          </div>
      </div>
      <MobileNav v-if="showNav"/>
  </header>
</template>

<script>
import { ref } from '@vue/reactivity'
import MobileNav from './MobileNav.vue'
export default {
    name: "Header",
    setup() {
        const showNav = ref(null);

        return {showNav}
    },
    components: { MobileNav }
}
</script>

<style scoped>
header{
    position: sticky;
    top: 0;
    z-index: 2000;
    background: #f1f1f1;
}
header .top .container{    
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgb(233, 227, 227);
}
header .top .container .branding img{
    width: 20px;
    margin: 0 auto;  
    cursor: pointer;
}
header .container .contact{
    display: flex;
    align-items: center;
}
header .container .time{
    display: none;
}
header .top{
    padding-top: 1.5rem;
    
}
header .top .contact h5, header .top .time h5{
    font-family: 'Inter', sans-serif;
    margin-bottom: 0.25rem;
    color: #423838;
}
header .top .contact h6, header .top .time h6{
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.055rem;
    font-size: 0.70rem;
    color: var(--ternary);
}
header .top .contact img, header .top .time img{
    margin-right: 1rem;
}
@media (min-width:1100px) {
    header{
    position: relative;
    background: #fff;
}
    header .top .container{
        border: none;
        padding-bottom: 0;
    }
    header .top .container .branding{
        display: none;
    }
    header .container .time{
    display: block;
}
}
</style>