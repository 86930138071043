<template>
  <div class="subnav-wrapper">
      <div class="mega-menu">
          <div class="menu">
              <ul>
                  <li> <router-link :to = "{name: 'Department'}">Departments and HODs<p>Departments and Head of Departments</p></router-link></li>
                  <li><router-link :to = "{name: 'Counselling'}">Guidance &amp; Counselling<p>Counselling program in the school</p></router-link></li>
                  <li><router-link :to = "{name: 'Courses'}"> Courses Offered<p>Streams available in our school</p></router-link></li>
                  <li><router-link :to = "{name: 'Publications'}"> Publications<p>Read Publications by our teachers</p></router-link></li>
                  
              </ul>
          </div>
          <div class="menu">
              <ul>
                 
                      <li><router-link :to = "{name: 'Literary'}">Literary Activities<p>Literary activities in the school</p></router-link></li>
                       
                 <li><router-link :to = "{name: 'ClassTeachers'}">Class Teachers<p>Know your class teachers</p></router-link></li>
                  
                  <li><router-link :to = "{name: 'Toppers'}">Academic Toppers<p>Academic toppers 2021</p></router-link></li>

              </ul>
          </div>
          
          
      </div>
  </div>
</template>

<script>
export default {
    
    
}
</script>

<style scoped>
.subnav-wrapper{
    
    width: 450px;  
}
</style>

