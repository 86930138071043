<template>
  <AddEvent/>
  <ShowEvents :event="event" v-for="(event,id) in documents" :key="id"/>
</template>

<script>
import AddEvent from "../components/AddEvent.vue";
import ShowEvents from "../components/ShowEvents.vue";
import getCollection from '../composables/getCollection';
export default {
    name:"Events",
    components: { AddEvent, ShowEvents },

    setup(){
      const {documents} = getCollection('events')

      return {documents}
    }
}
</script>

<style>

</style>