<template>
  <nav>     
    <div class="navbar magic-shadow">
      <div class="container" >         
            <router-link :to = "{name:'Home'}">Home</router-link>
            <a href="#" class="submenu" @mouseover="showMenu" @click="hideMenu"
            @mouseleave="hideMenu">About Us <div v-show="isVisible" ><About/></div></a>
            <a href="#" class="submenu" @mouseover="showMenu0"  @click="hideMenu0"
            @mouseleave="hideMenu0"> Staffs <div v-show="isVisible0"><Staffs/></div></a>
            
            <a href="#" class="submenu" @mouseover="showMenu1"  @click="hideMenu1"
            @mouseleave="hideMenu1">Academics <div v-show="isVisible1"><Academic/></div></a>

            

            <a href="#" class="submenu" @mouseover="showMenu2"  @click="hideMenu2"
            @mouseleave="hideMenu2">Non-academics<div v-show="isVisible2"><NonAcademic/></div></a>
            <router-link :to = "{name: 'HomeBlogs'}">Blog</router-link>
            <router-link :to = "{name: 'Downloads'}">Downloads</router-link>
            <a href="https://www.result.yhss.edu.bt" target="_blank">Result</a>
         
      </div>
        

  </div>
  </nav>
 
</template>

<script>
import About from "./submenu/About.vue"
import Staffs from "./submenu/Staffs.vue"
import Academic from "./submenu/Academic.vue"
import NonAcademic from "./submenu/NonAcademic.vue"

export default {
    data(){
        return{
            isVisible: false,
            isVisible0: false,
            isVisible1: false,
            isVisible2: false,
        }
    },
    methods:{
        showMenu(){
            this.isVisible = true
        },
        hideMenu(){
            this.isVisible = false
        },
        showMenu0(){
            this.isVisible0 = true
        },
        hideMenu0(){
            this.isVisible0 = false
        },
        showMenu1(){
            this.isVisible1 = true
        },
        hideMenu1(){
            this.isVisible1 = false
        },
        showMenu2(){
            this.isVisible2 = true
        },
        hideMenu2(){
            this.isVisible2 = false
        }
    },
    components:{ About, Staffs, Academic, NonAcademic },
    
}
</script>

<style scoped >
nav {
    position: sticky;
    top: 0;
    z-index: 2001;
    
}
nav .navbar{
    display: none;
}

@media (min-width:1100px) {
    nav .navbar{
    display: block;    
}
    nav .navbar .container{
    display: flex;
    justify-content: center;  
    
}
    nav .navbar .container .submenu{
        position: relative;  
    }
    nav .navbar a{
        text-decoration: none;
        color: var(--footer);
        text-transform: uppercase;
        font-size: 0.95rem;
        padding: 1rem 1.5rem;    
        transition: all 0.3s ease 0s;
        font-family: 'Rajdhani', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        transition: .5s ease all;
    }
    nav .navbar a.router-link-exact-active{    
        background: var(--primary);
        color: var(--pure);
    }

    nav .navbar a:hover{
        background: var(--primary);
        color: var(--pure);
    }
        
}
</style>