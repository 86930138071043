<template>
  <div id="post-view">
      <div class="wrapper quillwrapper">
          <h2>{{this.blogTitle}}</h2>
          <!-- <img :src="blogCoverPhoto" alt=""> -->
          <div class="post-content ql-editor" v-html="blogHTML">

          </div>
      </div>
  </div>
</template>

<script>
export default {
    name:"PostPreview",
    computed:{
        blogTitle(){
            return this.$store.state.blogTitle
        },
        blogHTML(){
            return this.$store.state.blogHTML
        },
        blogCoverPhoto(){
            return this.$store.state.blogPhotoFileURL
        }
    }
}
</script>

<style scoped>
#post-view{
    min-height: 100%;
}
#post-view .wrapper{
    padding: 60px 25px;
}
#post-view .wrapper .ql-editor{
    padding: 0;
}
#post-view .wrapper h2{
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 32px;
}
#post-view .wrapper img{
    width: auto;
    max-width: 100%;  
    margin-bottom: 1rem;

}
#post-view .wrapper .post-content{
    overflow: hidden;
}
</style>