<template>
<div class="error" v-if="error">{{error}}</div>
  <div class="blog-card">
            <div class="icons" v-show="editAnn">
                <div @click="handleEdit" class="icon">
                    <img src="../assets/icon/edit-regular.svg" alt="" class="edit">
                </div>
                <div  @click="handleDelete" class="icon" >
                    <img src="../assets/icon/trash-regular.svg" alt="" class="delete">
                </div>
            </div>
            <!-- <div class="cardImg">
                <img :src="post.blogCoverPhoto" >
            </div> -->
           <div class="info">
               <h4>{{ann.annTitle}}</h4>
               <h6>Posted on: {{ann.date.toDate().toLocaleString('eng-us',{dateStyle:"long"})}}</h6>
               <!-- <p v-html="snippet"></p> -->
               <router-link class="link" :to="{name:'ViewAnnouncement', params: {id:ann.id} }">
                   View Details <img src="../assets/icon/arrow-right-light.svg" class="arrow" alt="">
               </router-link>
           </div>    
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import store from '../store'
import useDocument from '../composables/useDocument';
import { useRouter } from 'vue-router';


export default {
    name: "annCard",
    props: ["ann"],
    
    setup(props) {     
        const router = useRouter()
        const {error, deleteDoc} = useDocument('announcements', props.ann.id)
        
        
        // Delete ann 
        const handleDelete = async()=>{            
            
            await deleteDoc()
        }
        const handleEdit = ()=>{
            router.push({name:'EditAnn', params: {id: props.ann.id}})
        }
        const editAnn = computed(() => {
            return store.state.editAnn;
        });
        // const snippet = computed(() => {
        //     return props.post.blogHTML.substring(0, 100) + "...";
        // });
        return { editAnn,handleDelete,handleEdit,error };
    },
  
}
</script>

<style scoped>
.blog-card{
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #fff;
    min-height: 100px;
    transition: .5s ease all;
}
.blog-card:hover{
    transform: rotateZ(-1deg) scale(1.01);
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
}
.blog-card .icons{
    display: flex;
    position: absolute;
    top: 3px;
    right: 10px;
    z-index: 99;
}
.blog-card .icons .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #303030;
    transition: 0.5s ease all;
}
.blog-card .icons .icon img{
    filter: invert(100%);
}

.blog-card .icons .icon:hover{
    background: var(--primary);

}
.blog-card .icons .icon .edit, .blog-card .icons .icon .delete{
    fill: #fff;
    pointer-events: none;
    height: 15px;
    width: auto;
}
.blog-card .icons .icon:nth-child(1){
    margin-right: 8px;
}

.blog-card .info{
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 3;
    padding: 32px 13px;
    columns: #000;
}
.blog-card .info h4{
    padding-bottom: 8px;
    font-size: 20px;
    font-weight: 300;
}
.blog-card .info h6{
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 16px;
}
.blog-card .info .link{
    display: inline-flex;
    align-items: center;
    margin-top: auto;
    font-weight: 500;
    padding-top: 20px;
    font-size: 12px;  
    text-decoration: none;
    transition: 0.5s ease-in all;
    color: var(--secondary);
}
.blog-card .info .link:hover{
    color: var(--primary);
}
.blog-card .info .link .arrow{
    margin-left: 1rem;
    width: 10px;
}
</style>