import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/style.css'

import InstantSearch from 'vue-instantsearch/vue3/es';



import {projectAuth} from './firebase/config'
let app 
projectAuth.onAuthStateChanged(()=>{
    if (!app){
        app = createApp(App).use(store).use(InstantSearch).use(router).mount('#app')
    }
})
