<template>
  <section id="admin-header">
      <div class="header">
          <div class="menu-bar" @click="showNavBar">
              <img src="../assets/icon/bars-regular.svg" alt="mobile nav">
          </div>
          <div class="search-bar">
              <!-- <img src="../assets/icon/magnifying-glass-solid.svg" alt="">
              <input type="text" placeholder="Search"/> -->
              <Search/>
          </div>
          <div class="profile">
              <img src="../assets/icon/bell-solid.svg" alt="">
              <p @click="dropdown=!dropdown">KP</p>
            <div class="dropdown" v-if="dropdown">
                <div class="link" @click="dropdown=false">
                      <div v-if="user">
                        <img src="../assets/icon/envelope-regular.svg" alt="Email">
                       <router-link to="#">{{user.email}}</router-link>
                      </div>
                      
                      
                </div>
                <div class="link seperator" @click="dropdown=false">
                      <div><img src="../assets/icon/user-alt-light.svg" alt="Email">
                      <router-link :to="{name:'Profile'}">View Profile</router-link>
                      </div>
                      <img src="../assets/icon/greater-than-solid.svg" alt="" class="gt">
                      
                </div>
                <div class="link" @click="handleLogout">
                      <div><img src="../assets/icon/right-from-bracket-solid.svg" alt="Email">
                      <span>Log Out</span>
                      </div>
                      <img src="../assets/icon/greater-than-solid.svg" alt="" class="gt">
                      
                </div>
            </div>
          </div>
      </div>
  </section>
</template>

<script>
import { ref } from '@vue/reactivity'
import useLogout from '../composables/useLogout'
import { useRouter } from 'vue-router'
import getUser from '../composables/getUser'
import Search from './Search.vue'

export default {
    name: "AdminPage",
    setup(props, context) {
        const showNav = ref(false);
        const dropdown = ref(false);
        const router = useRouter();
        const { error, logout } = useLogout();
        const { user } = getUser();
        const handleLogout = async () => {
            await logout();
            if (!error.value) {
                router.push({ name: "Login" });
            }
        };
        const showNavBar = () => {
            showNav.value = !showNav.value;
            context.emit("showBar", showNav.value);
        };
        return { dropdown, handleLogout, user, showNavBar };
    },
    components: { Search }
}
</script>

<style scoped>
#admin-header{
    width: 100%;
} 
#admin-header .header{
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06); 
}
#admin-header .header .search-bar, #admin-header .header .profile{
    display: flex;
    align-items: center;    
    padding: 10px 14px;
    border-radius: 4px;   
}
#admin-header .header .menu-bar img{
    width: 20px;
    cursor: pointer;
    filter: invert(30%);
    
}
#admin-header .header .search-bar{
    border: 1px solid #d7dbe6;
    border-radius: 20px;
    max-width: 170px;   
}
#admin-header .header .search-bar img, #admin-header .header .profile img{
    width: 15px;
    margin-right: 1rem;
    filter: invert(50%);
}
#admin-header .header .search-bar input{
    border: none;
    outline: none;
    font-size: 14px;
    
}#admin-header .header .profile{
    position: relative;
}
#admin-header .header .profile p{
    width: 40px;
    height: 40px;
    font-size: 20px;   
    border-radius: 50%;
    background: rgb(228 230 235);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
#admin-header .header .profile .dropdown{
    background: #303030;
    color: #f1f1f1;
    padding: 1rem;
    border-radius: 0 0 10px 10px;
    position: absolute;  
    top: 55px;
    right: 0;
    z-index: 1002;
    min-width:250px;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2),0 2px 4px 0 rgba(0, 0, 0, 0.1),inset 0 0 0 1px rgba(255, 255, 255, 0.5);  
}
#admin-header .header .profile .dropdown .seperator{
    border-top: 1px solid rgb(211, 205, 205);
    border-bottom: 1px solid rgb(211, 205, 205);
}
#admin-header .header .profile .dropdown .link{
  padding: 0.7rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; 
  
}
#admin-header .header .profile .dropdown .link .gt{
    width: 7px;
    filter: invert(50%);
}
#admin-header .header .profile .dropdown .link:hover{
  background: rgb(58, 49, 36);
}
#admin-header .header .profile .dropdown .link img{
  width: 15px;
  filter: invert(50%);
}
#admin-header .header .profile .dropdown .link div{
  display: flex;
  align-items: center; 
}
#admin-header .header .profile .dropdown .link a{
  text-decoration: none;
  color: #f1f1f1;
}
@media (min-width:768px) {
    #admin-header .header .search-bar{
        max-width: 300px;
    }
}
</style>