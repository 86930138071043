<template>
  <section>
      <div class="annoucements">
          <div class="container">
              <div class="wrapper">
                  <div class="ann">
                  <h2>Announcements</h2>
                    <Announcements/>
                    <h5><router-link :to = "{name:'HomeAnn'}">View All Announcements</router-link></h5>
                  

              </div>
              <div class="ann">
                  <h2>Upcoming Events</h2>
                    <HomeEvents/>
                    <h5><router-link :to = "{name:'HomeEvents'}">View Upcoming Events</router-link></h5>
                    

              </div>
              <div class="ann">
                  <h2>About Our School</h2>
                  <div class="vision">
                      <h3>Vision </h3>
                      <p>Yangchenphug aspires to be the center of academic excellence in providing Senior Secondary Education with a pleasant ambience and learning atmosphere conducive to the total personality development of the learners.</p>
                  </div>
                  <Stats/>
                   <h5><router-link :to = "{name:'AboutUs'}">Read More</router-link></h5>
              </div>
              </div>
          </div>
      </div>
  </section>
  
</template>

<script>
import Announcements from "./Announcements.vue";
import HomeEvents from "./HomeEvents.vue";
import Stats from "./Stats.vue";


export default {
    name: "Section",
    components: { Announcements, HomeEvents, Stats },
    setup(){}
}

</script>

<style scoped>
section .annoucements{
    background:#f9f9f9; 
 
}
section .annoucements .container .wrapper{
        display: block;
        width: 100%;
    }
section .annoucements .ann{
    background: var(--pure);
    text-align: center;
    padding: 1rem;        
    margin-right:0.5rem ;    
    width: 100%;
   
}
section .annoucements .ann .vision h3{
    text-align: left;
}
section .annoucements .ann p{
    text-align: justify;
}
section .annoucements .ann h2{
    font-size: 1.2rem;
    font-family: 'Rajdhani', sans-serif;
    letter-spacing: 0.05rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    font-weight: 600;
    color: var(--secondary);
    border-bottom: 1px solid var(--primary);
}
section .annoucements h5{
    padding: 0.5rem 0;
    font-size: 0.8rem;
}
section .annoucements a{
    text-decoration: none;
    color: #131571;
}
section .annoucements a:hover{
    color:rgb(235, 223, 223);
    font-weight: 300;
}
section .annoucements h5:hover{
    cursor: pointer;
    background: var(--primary);
    transition: all 0.3s ease 0s;
}
@media (min-width:1100px) {
    section .annoucements .container .wrapper{   
    margin-right: -2rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0; 
}
}
</style>