import { ref } from "vue";
import { projectAuth } from "../firebase/config"

const error = ref(null)

const forgotPassword = async(email)=>{
    error.value = null 
    try{
        const res = await projectAuth.sendPasswordResetEmail(email)
        error.value = null
        return res
    }catch(err){
        error.value = "There is no user record corresponding to this email. Enter correct email and try again!"
    }
    
}

const useForgotPassword = ()=>{
    return {error, forgotPassword}
}

export default useForgotPassword