import { ref} from '@vue/reactivity'
import { projectFirestore } from '../firebase/config'

const useDocument = (collection,id)=>{
    const error = ref(null)
    const loading = ref(false)

    let docRef = projectFirestore.collection(collection).doc(id)
    const deleteDoc = async()=>{
        loading.value = true
        error.value = null
        
        try{
            const res = await docRef.delete()           
            loading.value=false
            return res
        }catch(err){
            loading = false
            error.value = "Could not delete the document"
        }
    }
    
    
    return {error, loading, deleteDoc}
}

export default useDocument