<template>
<article class="news">
    <div class="container">
        <div class="header">
            <h1 class='section-heading'>Latest News and Events</h1>
            <router-link :to="{name: 'HomeBlogs'}">View All<img src="@/assets/icon/angles-right-solid.svg" alt=""></router-link>
        </div>
        <div class="news-wrapper">
            <article class="news-card" v-if="documents">
                <HomeBlogCard :post="post" v-for="(post,id) in documents.slice(0,4)" :key="id"/>                
            </article>
            <div v-else class="posts">
                <Loading/>
            </div>            
        </div>
    </div>
</article>
</template>

<script>
import getCollection from '../../composables/getCollection'
import HomeBlogCard from "./HomeBlogCard.vue"
import Loading from '../Loading.vue'

export default {
    name: "NewsEvents",
    setup(){
        const {documents} = getCollection('blogPosts')
        
        return {documents}
    },
    components: { HomeBlogCard, Loading }
}
</script>

<style scoped>

article.news .container h1{
    padding-top: 2rem;
}
article.news .container  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
article.news .container  .header a{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #111;
    font-size: 13px;
}
article.news .container  .header a img{
    width: 12px;
    margin-left: 0.5rem;
    filter: invert(30%);
}
article.news{
    background: #f4f4f4;
    position: relative;
    z-index: 0;
    margin-bottom: 1rem;  
    }
.news .news-wrapper{
    padding-bottom: 1rem;
    }
.news .news-wrapper .news-card{
    display: grid;
    grid-template-columns: auto;
    column-gap: 1rem;
    row-gap: 2rem;
}
.news .news-wrapper .posts{
    display: flex;
    justify-content: center;
}
@media (min-width:600px){
    .news .news-wrapper .news-card{
    grid-template-columns: auto auto;
    }
}
@media (min-width:900px){
    .news .news-wrapper .news-card{
    grid-template-columns: auto auto auto;
    }
}
@media (min-width:1100px){
    .news .news-wrapper .news-card{
    grid-template-columns: auto auto auto auto;
    }
}

</style>