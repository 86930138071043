<template>
  <div class="info" v-if="ann">
            <router-link :to="{name:'HomeViewAnnouncement', params: {id:ann.id} }">
               <h4>{{ann.annTitle}}</h4>
            </router-link>
               <h6><span>Posted on:</span> {{ann.date.toDate().toLocaleString('eng-us',{dateStyle:"long"})}}</h6>
           </div> 
</template>

<script>
export default {
    props: ["ann"],
}
</script>

<style scoped>
.info {
    text-align: left;
    border-bottom: 1px solid rgb(197, 186, 186);
    margin: 0.5rem 0;
    padding: 0.2rem;
}
.info a{
    text-decoration: none;

}
.info a h4{
    color: var(--secondary);
    font-weight: 400;
    font-size: 0.9rem;
}
.info h6{
    padding: 0.5rem 0;
    font-size: 0.8rem;
    color: #736d66;
    font-weight: 400;
}
.info h6 span{
    color: #303030;
}
.info a h4:hover{
    
    color: var(--primary);
    transition: all 0.3s ease 0s;
}
</style>
