<template>
  <div class="create-post">
      <BlogCoverPreview v-show="this.$store.state.blogPhotoPreview" v-if="this.blogCoverPhoto"/>
      <Spinner v-show="loading"/>
      <div class="wrapper" v-if="currentBlog">
          <div :class="{invisible: !error}" class="err-message">
              <p><span>Error: </span>{{this.errorMsg}}</p>
          </div>
          <div class="blog-info">
              <input type="text" placeholder="Enter blog title" v-model="blogTitle">
              <div class="upload-file">
                  <label for="blog-photo">Upload Cover Photo</label>
                  <input type="file" ref="blogPhoto" id="blog-photo" @change="fileChange" accept=".png, .jpg, .jpeg">
                  <button @click="openPreview" class="preview" :class="{'button-inactive': !this.$store.state.blogPhotoFileURL}">Preview Photo</button>
                  <span>File Chosen: {{this.$store.state.blogPhotoName}}</span>
              </div>
          </div>
          <div class="editor">             
              <QuillEditor toolbar="full" v-model:content="blogHTML" contentType="html"/>
              <!-- <vue-editor :editorOptions="editorSettings" v-model="blogHTML" useCustomImageHandler/> -->
          </div>
          <div class="blog-actions">
              <button @click="updateBlog">Save Changes</button>
              <router-link :to="{name:'PostPreview'}" class="router-button">Preview Changes</router-link>
          </div>
      </div>
  </div>
</template>

<script>

 
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import store from '../store';
import BlogCoverPreview from '../components/BlogCoverPreview.vue';

import { projectFirestore } from '../firebase/config';
import {projectStorage} from '../firebase/config'
import Spinner from '../components/Spinner.vue';



export default {
    name: "EditPost",
    components:{ QuillEditor, BlogCoverPreview, Spinner },
   
    data(){
        return{
            file: null,
            error: null,
            errorMsg: null,
            loading: null,
            routeID : null,    
            currentBlog: null,

        } 
    },
    
    async mounted(){ 
        this.routeID = this.$route.params.id;
        this.currentBlog = await this.$store.state.blogPosts.filter(post =>{
            return post.blogID === this.routeID
        });
        this.$store.commit('setBlogState', this.currentBlog[0])
        
    },
    methods:{
        
        fileChange(){
            this.file = this.$refs.blogPhoto.files[0];
            const fileName = this.file.name;
            this.$store.commit("fileNameChange",fileName);
            this.$store.commit("createFileURL",URL.createObjectURL(this.file));

        },
        openPreview(){            
            this.$store.commit("openPhotoPreview")
        },
        async updateBlog(){
            const dataBase = await projectFirestore.collection('blogPosts').doc(this.routeID)
            if(this.blogTitle.length !== 0 && this.blogHTML.length !== 0){
                if(this.file){                 
                    this.loading = true
                    const storageRef = projectStorage.ref()
                    const docRef = storageRef.child(`blog/coverPhotos/${this.$store.state.blogPhotoName}`)
                    docRef.put(this.file).on("state_changed",(snapshot)=>{
                        // delete this
                        
                    },
                    (err)=>{
                        this.error = err
                        this.loading = false
                    },
                    async()=>{
                        const downloadURL = await docRef.getDownloadURL()                   

                        await dataBase.update({                           
                            blogHTML: this.blogHTML,
                            blogCoverPhoto: downloadURL,
                            blogCoverPhotoName: this.blogCoverPhoto,
                            blogTitle: this.blogTitle,
                          
                        });
                        await this.$store.dispatch("updatePost", this.routeID)
                                              
                        this.loading = false
                        this.$router.push({name:'ViewPost',params:{id: dataBase.id}})
                    }
                    )
                    
                    return
                }
                this.loading = true
                await dataBase.update({
                    blogHTML: this.blogHTML,
                    blogTitle: this.blogTitle
                })
                await this.$store.dispatch('updatePost', this.routeID)
                this.loading = false
                this.$router.push({name:"ViewPost", params:{id: dataBase.id}})
                return
            }
            this.error = true
            this.errorMsg = "Please ensure Blog Title & Blog Post has been filled!"
            setTimeout(()=>{
                this.error = false
            },5000)
            return
        },
    },
    computed:{
        profileId(){
            return this.$store.state.profileId;
        },
        blogCoverPhoto(){
            return this.$store.state.blogPhotoName;
        },
        blogTitle:{
            get(){
                return this.$store.state.blogTitle;
            },
            set(payload){
                this.$store.commit("updateBlogTitle",payload)
            },
        },
        blogHTML:{
            get(){
                return this.$store.state.blogHTML;
            },
            set(payload){
                store.commit("newBlogPost",payload)
            },
        },
    },
}
</script>

<style>
.create-post{
    
    position: relative;
    height: 100%;
}
.create-post button{
    margin-top: 0;
}
.create-post .router-button{
    text-decoration: none;
    color: #fff;
}
.create-post label, .create-post button, .create-post .router-button{
    transition: 0.5s ease-in-out all;
    align-self: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 20px;
    padding: 12px 24px;
    color: #fff;
    background: #303030;
    text-decoration: none;
}
.create-post .wrapper{
    position: relative;
    height: 100%;
    padding: 10px 25px 60px;
}
.create-post .wrapper .invisible{
    opacity: 0 !important;
   
}
.create-post .wrapper .err-message{
    width: 100%;
    padding: 12px;
    margin-bottom: 1rem;
    border-radius: 8px;
    color: #fff;
    background: #303030;
    opacity: 1;
    transition: 0.5s ease all;
}
.create-post .wrapper p{
    font-size: 14px;
}
.create-post .wrapper p span{
    font-weight: 600;
}
.create-post .wrapper .blog-info{
    display: flex;
    margin-bottom: 32px;
}
.create-post .wrapper .blog-info input:nth-child(1){
    min-width: 300px;
}
.create-post .wrapper .blog-info input{
    transition: 0.5s ease-in-out all;
    padding: 10px 4px;
    border: none;
    border-bottom: 1px solid #303030;

}
.create-post .wrapper .blog-info input:focus{
    outline: none;
    box-shadow: 0 1px 0 0 #303030;
}
.create-post .wrapper .blog-info .upload-file{
    flex: 1;
    margin-left: 16px;
    position: relative;
    display: flex;
}
.create-post .wrapper .blog-info .upload-file .button-inactive{
    background: rgb(143, 134, 134);
}
.create-post .wrapper .blog-info .upload-file input{
    display: none;
}
.create-post .wrapper .blog-info .upload-file .preview{
    margin-left: 16px;
    text-transform: initial;
     border: none;
}
.create-post .wrapper .blog-info .upload-file  span{
    font-size: 12px;
    margin-left: 16px;
    align-self: center;
}
.create-post .wrapper .editor{
    height: 60vh;
    display: flex;
    flex-direction: column;
}
.create-post .wrapper .editor .quillWrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.create-post .wrapper .editor .ql-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: scroll ;
}
.create-post .wrapper .editor .ql-editor{
    padding: 20px 16px 30px;
}
.create-post .wrapper .blog-actions{
    margin-top: 32px;
}
.create-post .wrapper .blog-actions button{
    margin-right: 16px;
    border: none;
}
</style>