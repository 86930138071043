<template>
  <TopBar/>
  <Header/>
  <NavBar/>
  <HomeAnnouncementSection/>
  <HeroSection/>
  <Section/>
  <Banner/>
  <NewsEvents/>
  <Footer/>
</template>

<script>
import TopBar from "../components/page/TopBar.vue";
import Header from "../components/page/Header.vue";
import NavBar from "../components/page/NavBar.vue";
import HeroSection from "../components/page/HeroSection.vue";
import Section from "../components/page/Section.vue";
import NewsEvents from "../components/page/NewsEvents.vue";
import Footer from "../components/page/Footer.vue";
import HomeAnnouncementSection from "../components/page/HomeAnnouncementSection.vue";
import Banner from "../components/page/Banner.vue";


export default {
  name: 'Home',
  components: {
    TopBar,
    Header,
    NavBar,
    HeroSection,
    Section,
    NewsEvents,
    Footer,
    HomeAnnouncementSection,
    Banner
}
}
</script>

<style>

</style>