<template>
  <div class="spin">
    <span></span>
  </div>
</template>

<script>
export default {
  name:"Spinner"
}
</script>

<style scoped>
.spin{
    top: 0;
    z-index: 101;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.spin span{
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border: 3px solid transparent;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  animation: spin 1s ease infinite;

}
@keyframes spin{
  to {transform: rotateZ(360deg)}
}
</style>