<template>
  <div class="add-event">     
      <div class="container">
          <form @submit.prevent="handleSubmit">
          <h4>Add a new event</h4>
          <label for="">Event: </label>
          <input type="text" placeholder="Event Name" required v-model="title">
          <label for="">Date: </label> 
          <input type="date" placeholder="Date" required v-model="date">          
          <button class="btn-save">Add Event</button>
      </form>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import useCollection from '../composables/useCollection'
export default {
    name: "AddEvent",
    setup(){ 
        const title = ref("")
        const date = ref("")
        
        const {error, addDoc,loading} = useCollection("events")

        const handleSubmit = async()=>{
            await addDoc({
                title: title.value,
                date: date.value
            })
            if(!error.value){
                title.value = ""
                date.value = ""
            }
        }

        return {title, date, handleSubmit}
    }

}
</script>

<style scoped>
.add-event{
  text-align: center;
  margin: 2rem 0;  
}
.add-event form{
  max-width: 100%;
  margin: 0 auto;
  padding: 30px;  
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
  text-align: left;
}
.add-event form h4{
    margin-bottom: 1rem;
    position: relative;
    padding-bottom: 0.5rem;
}
.add-event form h4::after{
    content:"";
    position: absolute;
    border-bottom: 1px solid #303030;
    width: 20%;
    bottom: 0;
    left: 0;
}
.add-event form input{
    width: 100%;
    border: none;
    background: white;
    padding: 4px 4px 4px 30px;
    height: 40px;
    font-size: 0.9rem;
}
.add-event form input[type="date"]{
    
    font-family: inherit;
}
.add-event form input:focus{
    outline:none;
    background: #f7eeee
}
</style>