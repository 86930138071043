<template>
    <section id="admin">
      <div class="navigation"   v-if="showValue">
        <AdminNavigation/>
      </div>
      <div class="admin-side">
        <div class="head">
          <AdminHeader  @showBar="showBar"/>
        </div>
        <div class="body">
            <h2>User Profile</h2>
           <div class="profile-content"> 
                
                  <div class="avatar">
                      kp
                  </div>
                  <div class="input">
                      <h3>Name:</h3>
                      <p>Admin</p>
                  </div>
                  <div class="input" v-if="user">
                      <h3>Email:</h3>
                      <p>{{user.email}}</p>
                  </div>
                  <button class="btn-save">Save Changes</button>
           </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import AdminNavigation from "../components/AdminNavigation.vue";
  import AdminHeader from "../components/AdminHeader.vue";
  import getUser from '../composables/getUser';
import { ref } from '@vue/reactivity';
  export default {
    name:'Profile',
      components: { AdminNavigation, AdminHeader },
      setup(){
          const {user} = getUser()
          const showValue = ref(null)
          const showBar = (show)=>{
          showValue.value = show
      }
          return {user,showBar,showValue}
          
      }
  }
  </script>
  
  <style scoped>
  #admin .admin-side .body {
      height: 100vh; 
      padding-top: 1rem;
  }
  #admin .admin-side .body .profile-content{
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06); 
      padding: 32px;
      background: #f4f4f4;
      display: flex;
      flex-direction: column;    
      max-width: 600px;
      margin: 1rem auto;
  }
  #admin .admin-side .body h2{
      margin-top: 1rem;
      text-align: center;
  }
  #admin .admin-side .body .profile-content .avatar{
      width: 50px;
      margin: 0 auto;
      height: 50px;
      background: #303030;
      color: #f1f1f1;
      padding: 2rem;
      font-size: 2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
  }
  #admin .admin-side .body .profile-content .input{
      margin: 0.5rem 0;
  }
  #admin .admin-side .body .profile-content .input label{
      font-size: 14px;
      display: block;
      padding-bottom:6px;
  }
  #admin .admin-side .body .profile-content .input input{
      width: 100%;
      border: none;
      background: #f2f7f6;
      padding: 8px;    
  }
  #admin .admin-side .body .profile-content .input input:focus{
      outline: none;
  }
 
  </style>