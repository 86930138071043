<template>
  <nav>
      <div class="container">
          <ul v-if="mobileView">
          <li><router-link :to = "{name:'Home'}" @click="changeNav">
              Home</router-link>
              
          </li>
          <li class="icons">About Us <img src="@/assets/icon/plus-solid.svg" alt="" @click="showmenu" v-if="plus">
           <img src="@/assets/icon/xmark-solid.svg" v-if="isVisible" @click="showmenu"></li>
              <ul class="submenu" v-if="isVisible" @click="changeNav">
                  <li><router-link :to = "{name: 'Background'}">Background</router-link></li>
                  <li><router-link :to = "{name: 'VisionMission'}">Vision and Mision</router-link></li>
                  <li><router-link :to = "{name: 'Organogram'}">Organogram</router-link></li>
                  <li><router-link :to = "{name: 'Principals'}">List of Principals</router-link></li>
                  <li><router-link :to = "{name: 'Committees'}">Committees</router-link></li>
                  <li><router-link :to = "{name: 'SchoolTiming'}">School Timing</router-link></li>
              </ul>
            
            <li class="icons">Staffs <img src="@/assets/icon/plus-solid.svg" alt="" @click="showmenu0" v-if="plus0">
           <img src="@/assets/icon/xmark-solid.svg" v-if="isVisible0" @click="showmenu0"></li>
              <ul class="submenu" v-if="isVisible0" @click="changeNav">
                  <li><router-link :to = "{name: 'SchoolManagement'}">School Management</router-link></li>
                  <li><router-link :to = "{name: 'TeachingStaffs'}">Teaching Staffs</router-link></li>
                  <li><router-link :to = "{name: 'NonTeachingStaffs'}">Non-Teaching Staffs</router-link></li>
                
              </ul>
          
          <li>Academics <img src="@/assets/icon/plus-solid.svg" alt="" @click="showmenu1" v-if="plus1">
          <img src="@/assets/icon/xmark-solid.svg" v-if="isVisible1" @click="showmenu1"></li>
              <ul class="submenu" v-if="isVisible1" @click="changeNav" >
                  <li><router-link :to = "{name: 'Department'}">Department and HODs</router-link></li>
                  <li><router-link :to = "{name: 'ClassTeachers'}">Class Teachers</router-link></li>
                  <li><router-link :to = "{name: 'Courses'}">Course Offered</router-link></li>
                  <li><router-link :to = "{name: 'Literary'}">Literary Activities</router-link></li>
                  <li><router-link :to = "{name: 'Counselling'}">Guidance and Counselling</router-link></li>
                  <li><router-link :to = "{name: 'Toppers'}">Academic Toppers</router-link></li>
                  </ul>
          
          <li>Non-Academics <img src="@/assets/icon/plus-solid.svg" alt="" @click="showmenu2" v-if="plus2">
          <img src="@/assets/icon/xmark-solid.svg" v-if="isVisible2" @click="showmenu2"></li>
              <ul class="submenu" v-if="isVisible2" @click="changeNav">
                  <li><router-link :to = "{name: 'Clubs'}">Clubs</router-link></li>
                  <li><router-link :to = "{name: 'GamesSports'}">Games and Sports</router-link></li>
                  <li><a href="https://eba.yhss.edu.bt"  target="_blank">EBA</a> </li>
              </ul>
          
          <li><router-link :to = "{name: 'HomeBlogs'}" @click="changeNav">Blog</router-link></li>
          <li><router-link :to = "{name: 'Downloads'}" @click="changeNav">Downloads</router-link></li>
          <li><a href="https://www.result.yhss.edu.bt" target="_blank">Result</a></li>
      </ul>
      </div>
  </nav>
</template>

<script>
export default {
data(){
        return{
            isVisible: false,
            plus: true,
            isVisible0: false,
            plus0: true,
            isVisible1: false,
            plus1: true,
            isVisible2: false,
            plus2: true,
            mobileView: true,
            
        }
    },
    methods:{
        showmenu(){
            this.isVisible=!this.isVisible,
            this.plus = !this.plus
           
        },
        showmenu0(){
            this.isVisible0=!this.isVisible0,
            this.plus0 = !this.plus0
           
        },
        changeNav(){
            this.$emit('changeMobileNav', 'false')
        },
        showmenu1(){
            this.isVisible1=!this.isVisible1,
            this.plus1 = !this.plus1
           
        },
        showmenu2(){
            this.isVisible2=!this.isVisible2,
            this.plus2 = !this.plus2
           
        },
        handleview(){
            this.mobileView = window.innerWidth <= 1100;
    }
    },
    created(){
    this.handleview();
    window.addEventListener('resize',this.handleview);
  }
}
</script>

<style scoped>
nav{
    background: var(--secondary);
}

nav .container ul{
    list-style-type: none;
    color: var(--pure)
}
nav .container img{
    width: 13px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    filter: invert(100%);
   
    }
nav .container ul li{
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #292a66;
    line-height: 2rem;
}
nav .container ul .submenu{  
    transition: all 0.3s ease 0s;
}
nav .container ul .submenu a{
    padding-left: 2rem;
    font-size: 0.9rem;
}
nav .container a{
    color: var(--pure);
    text-decoration: none;
}

</style>