<template>
  <div class="modal">
      <div class="modal-content">
         <img src="../assets/icon/times-circle-light.svg" alt="" @click="closePreview" class="icon">
         <img :src="blogCoverPhoto" alt="" class="img">
      </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core';
import store from '../store';
export default {
   setup(){
       const closePreview=()=>{
           store.commit("openPhotoPreview")
       }

       const blogCoverPhoto = computed(()=>{
           return store.state.blogPhotoFileURL
       })

       return {closePreview,blogCoverPhoto}
   }
    
}
</script>

<style>
.modal{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0,0,0,0.5);
}
.modal .modal-content{
    display: flex;
    justify-content: center;
    border-radius: 12px;
    position: relative;
    width: 600px;
    height: auto;
    padding: 30px;
    background: #fff;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.modal .modal-content .icon{
    width: 24px;
    height: auto;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 15px;
    right: 15px;
    color: #1eb8b8;
}
.modal .modal-content .img{
    margin-top: 16px;
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;

    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2),0 2px 4px 0 rgba(0, 0, 0, 0.1),inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>