<template>
  <div class="subnav-wrapper">
      <div class="mega-menu">
          <div class="menu">
              <ul>
                  <li> <router-link :to = "{name: 'Clubs'}">Clubs<p>Club activities in the school</p></router-link></li>
                  <li><a target="_blank" href="https://eba.yhss.edu.bt"> EBA<p>Emotional bank account</p></a></li>
                  <li><router-link :to = "{name: 'GamesSports'}"> Games &amp; Sports<p>Games and sports activities in the shool</p></router-link></li>
                  
              </ul>
          </div>
          
          
          
      </div>
  </div>
</template>

<script>
export default {
    
    
}
</script>

<style scoped>
.subnav-wrapper{
    
    width: 300px;    
    left: -50px;
    
 
}

</style>

