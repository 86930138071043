<template>
  <section id="admin">
    <div class="navigation"   v-if="showValue">
      <AdminNavigation/>
    </div>
    <div class="admin-side">
            <div class="head">
              <AdminHeader  @showBar="showBar"/>
            </div>
            <div class="body">
              <div class="create-post">
           
            <Spinner v-show="loading"/>
            <div class="wrapper">
                <div :class="{invisible: !error}" class="err-message">
                    <p><span>Error : </span>{{this.errorMsg}}</p>
                </div>
                <div class="blog-info">
                    <input type="text" placeholder="Title" v-model="annTitle">
                </div>
                <div class="editor">             
                    <QuillEditor toolbar="full" v-model:content="annHTML" contentType="html"/>                  
                </div>
                <div class="blog-actions">
                    <button @click="uploadAnn">Publish Announcement</button>
                    <router-link :to="{name:'AnnPreview'}" class="router-button"> Preview</router-link>
                </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AdminNavigation from "../components/AdminNavigation.vue";
import AdminHeader from "../components/AdminHeader.vue";
 
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import store from '../store';
import BlogCoverPreview from '../components/BlogCoverPreview.vue';

import { projectFirestore } from '../firebase/config';

import Spinner from '../components/Spinner.vue';
import { timestamp } from '../firebase/config';
import { ref } from '@vue/reactivity';

import { computed } from '@vue/runtime-core';
import { useRouter } from 'vue-router';

export default {
  name:'CreateAnnouncement',
    components: { AdminNavigation, AdminHeader,  QuillEditor, BlogCoverPreview, Spinner },
    setup(){
        const error = ref(null)
        const errorMsg = ref(null)
        const router= useRouter()
        const loading = ref(null)
        const showValue = ref(null)

        const showBar = (show)=>{
        showValue.value = show
      }
        const annTitle = computed(
            {
                get(){
                    return store.state.annTitle
                },
                set(payload){
                    store.commit("updateAnnTitle",payload)
                }
            }
        )
        const annHTML = computed(
            {
                get(){
                    return store.state.annHTML
                },
                set(payload){
                    store.commit("newAnnHTML",payload)
                }
            }
        )

        const uploadAnn = async()=>{
            if (annTitle.value.length !==0 && annHTML.value.length !== 0){                         
                        loading.value = true
                        const dataBase = await projectFirestore.collection("announcements").doc()
                        await dataBase.set({
                            annID: dataBase.id,
                            annHTML: annHTML.value,                           
                            annTitle: annTitle.value,                        
                            date: timestamp(),
                        });
                        loading.value = false
                        await store.dispatch("getAnn")                                       
                        
                        router.push({name:'ViewAnnouncement',params:{id: dataBase.id}})
                        return
            }
            error.value = true
            errorMsg.value = "Please enter title and content!"
            setTimeout(()=>{
                error.value = false
            },5000)
            return
        }

        return {error, errorMsg, uploadAnn,loading,annTitle,annHTML,showBar,showValue}
    }
}
</script>

<style scoped>
#admin{
 display: flex;
}
#admin .navigation{
  flex: 1;
}
#admin .admin-side{
  flex: 4;
}.create-post{
    margin-top: 0.8rem;
    position: relative;
    height: 100%;
}
.create-post button{
    margin-top: 0;
}
.create-post .router-button{
    text-decoration: none;
    color: #fff;
}
.create-post label, .create-post button, .create-post .router-button{
    transition: 0.5s ease-in-out all;
    align-self: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 20px;
    padding: 12px 24px;
    color: #fff;
    background: #303030;
    text-decoration: none;
}
.create-post .wrapper{
    position: relative;
    height: 100%;
    padding: 10px 25px 60px;
}
.create-post .wrapper .invisible{
    display: none !important;
   
}
.create-post .wrapper .err-message{
    width: 100%;
    padding: 12px;
    margin-bottom: 1rem;
    border-radius: 8px;
    color: #fff;
    background: #303030;
    opacity: 1;
    transition: 0.5s ease all;
}
.create-post .wrapper p{
    font-size: 14px;
}
.create-post .wrapper p span{
    font-weight: 600;
}
.create-post .wrapper .blog-info{
    display: flex;
    margin-bottom: 32px;
}
.create-post .wrapper .blog-info input:nth-child(1){
    min-width: 300px;
}
.create-post .wrapper .blog-info input{
    transition: 0.5s ease-in-out all;
    padding: 10px 4px;
    border: none;
    border-bottom: 1px solid #303030;

}
.create-post .wrapper .blog-info input:focus{
    outline: none;
    box-shadow: 0 1px 0 0 #303030;
}
.create-post .wrapper .blog-info .upload-file{
    flex: 1;
    margin-left: 16px;
    position: relative;
    display: flex;
}
.create-post .wrapper .blog-info .upload-file .button-inactive{
    background: rgb(143, 134, 134);
}
.create-post .wrapper .blog-info .upload-file input{
    display: none;
}
.create-post .wrapper .blog-info .upload-file .preview{
    margin-left: 16px;
    text-transform: initial;
     border: none;
}
.create-post .wrapper .blog-info .upload-file  span{
    font-size: 12px;
    margin-left: 16px;
    align-self: center;
}
.create-post .wrapper .editor{
    height: 60vh;
    display: flex;
    flex-direction: column;
}
.create-post .wrapper .editor .quillWrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.create-post .wrapper .editor .ql-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: scroll ;
}
.create-post .wrapper .editor .ql-editor{
    padding: 20px 16px 30px;
}
.create-post .wrapper .blog-actions{
    margin-top: 32px;
}
.create-post .wrapper .blog-actions button{
    margin-right: 16px;
    border: none;
}
</style>