<template>
  <section id="admin">
    <div class="navigation" v-if="showValue">
      <AdminNavigation/>
    </div>
    <div class="admin-side">
      <div class="head">
        <AdminHeader  @showBar="showBar"/>
      </div>
      <div class="body">
          <ViewPost/>
      </div>
    </div>
  </section>
</template>

<script>
import AdminNavigation from "../components/AdminNavigation.vue";
import AdminHeader from "../components/AdminHeader.vue";
import ViewPost from "../components/ViewPost.vue";
import { ref } from '@vue/reactivity';
export default {
  name:'AdminPage',
    components: { AdminNavigation, AdminHeader, ViewPost },
     setup(){
      const showValue = ref(null)
      const showBar = (show)=>{
        showValue.value = show
      }
      return {
        showBar, showValue
      }
     }
}
</script>

<style>
#admin{
  height: 100vh; 
  display: flex;
}
#admin .navigation{
  flex: 1;
}
#admin .admin-side{
  flex: 4;
}
</style>