<template>
  <aside>
     <div class="events" v-if="eve">   
         <div class="date">
           <p class="date1">{{new Date(eve.date).getDate()}}</p>
           <p class="mon">{{new Date(eve.date).toLocaleDateString('en-us',{month:'short'})}}</p>
        </div>   
        <div class="title">
            <p>{{eve.title}}</p>
            
        </div>
        
     </div>
  </aside>
</template>

<script>

export default {
    props:['eve'],
    
}
</script>

<style scoped>
aside .events{
    display: flex;
    
    align-items: center;
    margin-bottom: 1rem;
  
}
aside .events .date{
    display: flex;
    flex-direction: column;
    background: var(--primary);
    width: 3.4rem;
    padding: 0.2rem 0.3rem;
    border-radius: 10px;
    font-weight: 400;
    color: #f3f3f3;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    font-size: 0.9rem;
    align-items: center;
    
    
 
}
aside .events .date .date1{
    font-size: 1.1rem;
}
aside .events .date .mon{
    font-size: 0.7rem;
}
aside .events .title{
    display: flex;
    margin-left: 1rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    
    
}
aside .events .title p{
    font-size: 0.9rem;
}
</style>