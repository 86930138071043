<template>
  <section id="accouncement">
      <div class="container news">
           <span>Announcement</span>
          <ul v-if="documents">
            <li>
                <BreakingAnn :ann="ann" v-for="(ann,id) in documents.slice(0,7)" :key="id"/>                
            </li>
          </ul>
       </div>
     
  </section>
</template>

<script>
import getCollection from "../../composables/getCollection"
import BreakingAnn from "./BreakingAnn.vue"


export default {
    name: "HomeAnnouncementSection",
    setup() {
        const { documents } = getCollection("announcements");
        return { documents };
    },
    components: { BreakingAnn }
}
</script>

<style scoped>
#accouncement .container {
  width: auto;
  height: 30px;
  margin: 10px auto;
  overflow: hidden;
  display: flex; 
  
} 
#accouncement .news{
    width: 100%;
    
}
#accouncement .container span {
    background: var(--secondary);
    color: #fff;
    padding: 0.2rem 0.4rem;
    border-radius: 10px 0px 0 0;
   
}

#accouncement .container ul {
  padding-left: 1rem;
  animation: ticker 10s cubic-bezier(1, 0, .5, 0) infinite;
    
}

#accouncement .container ul li {line-height: 30px; list-style: none; }

#accouncement .container ul li a {
    text-decoration: none;
    color: var(--ternary);
}
#accouncement .container ul li a:hover{
  color: var(--secodary);
}
@keyframes ticker {
	0%   {margin-top: 0}
	25%  {margin-top: -30px}
	50%  {margin-top: -60px}
	75%  {margin-top: -90px}
	100% {margin-top: 0}
}

#accouncement .container ul:hover { animation-play-state: paused; }
#accouncement .container span:hover+ul { animation-play-state: paused; }

@media (max-width:500px) {
  #accouncement .container ul,#accouncement .container span {
    font-size: 12px;
  }  
}
</style>