import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyAhxP2OyPZpwJoTlgxwt9mCKaIIfnddPDI",
    authDomain: "yhss-8e1b9.firebaseapp.com",
    projectId: "yhss-8e1b9",
    storageBucket: "yhss-8e1b9.appspot.com",
    messagingSenderId: "921388123583",
    appId: "1:921388123583:web:e2f1789edb9c818e588d0a"
  };

  // init firebase
  firebase.initializeApp(firebaseConfig)

  const projectAuth = firebase.auth()
  const projectFirestore = firebase.firestore()
  const projectStorage = firebase.storage()
  const timestamp = firebase.firestore.FieldValue.serverTimestamp
 
  export {projectFirestore, projectAuth, timestamp, projectStorage}