<template> 
  <router-view/>
</template>
<script>
export default {
  created(){
        this.$store.dispatch("getPost")
        this.$store.dispatch("getAnn")
    },
}
</script>
