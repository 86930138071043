<template>
  <section id="login">
      <div class="login-wrapper">
          <div class="left">
              <div>
                <img src="../assets/img/logo.png" alt="Yangchenphug HSS">
                <h1>Yangchenphug HSS</h1>
              </div>
             <div>                
                <router-link :to="{name:'Home'}"><h3>Home</h3>
                <img src="../assets/icon/arrow-right-light.svg" alt="">
                </router-link>
             </div>
          </div>
          <div class="right">
              <form @submit.prevent="handleSubmit">
                  <h2>Admin Login</h2>
                  <div class="inputs">
                      <div class="input">
                        <input type="email" placeholder="Email" v-model="email" required>
                        <img src="../assets/icon/envelope-regular.svg" alt="" class="icon">
                      </div>
                      <div class="input">
                        <input type="password" placeholder="Password" v-model="password" required autocomplete="off">
                        <img src="../assets/icon/lock-alt-solid.svg" alt="" class="icon">
                      </div>
                    
                  </div>
                  <div class="error">{{error}}</div>
                  <router-link :to="{name:'ForgotPassword'}" class="forgot-password">Forgot your password?</router-link>
                 
                 <button>Login</button>
              </form>
          </div>
      </div>
  </section>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import useLogin from '../composables/useLogin'

export default {
    name:"login",
    setup(){
        const email = ref("")
        const password = ref("")
        const router = useRouter()

        const {error,login} = useLogin()

        const handleSubmit = async()=>{
            await login(email.value, password.value)
            if(!error.value){
                router.push({name:'AdminPage'})
            }
        }

        return {email,password,handleSubmit,error}
    }
}
</script>

<style>
#login .login-wrapper{
    height: 100%;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
    width: 100%;
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);   
}
#login .login-wrapper .left{
    background: var(--secondary);
    width: 100%;    
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: #f1f1f1;
    
}
#login .login-wrapper .left h1{
    font-family: 'Cookie', cursive;
    font-size: 2rem;
    font-weight: 400;
}
#login .login-wrapper .left h3{
    font-weight: 400;
}
#login .login-wrapper .left div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#login .login-wrapper .left img{
    width: 100px;
}
#login .login-wrapper .left a{
    text-decoration: none;
    color:#f1f1f1;
    display: flex;
    align-items: center;
}
#login .login-wrapper .left a img{
    margin-left: 1rem;
    width: 20px;
    filter: invert(60%);
}
#login .login-wrapper .left .user{
    width: 30px;
    padding-bottom: 1rem;
    filter: invert(70%);

}
#login .login-wrapper .right{
    background: #f4f4f4;   
    height: 100%;
  
}
#login .login-wrapper .right form{    
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;  
    position: relative;
    align-items: center;
    
}

#login .login-wrapper .right form h2{
    text-align: center;
    font-size: 32px;
    color: #303030;
    margin-bottom: 1.5rem;
}
#login .login-wrapper .right form .inputs{
    width: 100%;
    max-width: 400px;
}
#login .login-wrapper .right form .inputs .input{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
#login .login-wrapper .right form .inputs .input input{
    width: 100%;
    border: none;
    background: white;
    padding: 4px 4px 4px 30px;
    height: 40px;
}
#login .login-wrapper .right form .inputs .input input:focus{
    outline:none;
    background: #f7eeee
}
#login .login-wrapper .right form .inputs .input .icon{
    width: 12px;
    position: absolute;
    left: 10px;

}
#login .login-wrapper .right form .forgot-password{
    text-decoration: none;
    color: #303030;
    cursor: pointer;
    font-size: 14px;
    margin: 1rem;
    padding-bottom: 5px;
    border-bottom: 1px solid transparent;
    transition: 0.5s ease all;    
}
#login .login-wrapper .right form .forgot-password:hover{
    border-color: var(--primary);
}
#login .login-wrapper .right form button{
    width: 100%;
    max-width: 350px;
    padding: 12px 0;
    background: var(--secondary);
    color: #f1f1f1;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    text-transform: uppercase;
}
.error{
    color: var(--danger);
}
@media (min-width:900px) {
    #login .login-wrapper{
        display: flex;
        width: 70%;
        height: 70vh;
    }
    #login .login-wrapper .left{
        width: 40%;
        padding: 1rem 0;
    }
    #login .login-wrapper .right{
        width: 60%;
    }
    
}
</style>