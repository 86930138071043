<template>
  <section id="admin">    
      <div class="navigation"   v-if="showValue">
      <AdminNavigation/>
    </div>
   
    <div class="admin-side">
      <div class="head">
        <AdminHeader  @showBar="showBar"/>
      </div>
      <div class="body">
          <ViewAnn/>
      </div>
    </div>
  </section>
</template>

<script>
import AdminNavigation from "../components/AdminNavigation.vue";
import AdminHeader from "../components/AdminHeader.vue";
import { ref } from '@vue/reactivity';
import ViewAnn from "../components/ViewAnn.vue";
export default {
  name:'AdminPage',
  
    components: { AdminNavigation, AdminHeader, ViewAnn },
    setup(){
      const showValue = ref(null)
      const showBar = (show)=>{
        showValue.value = show
      }
      return {
        showBar, showValue
      }
    }
    
}
</script>

<style>
#admin{
  height: 100vh; 
  display: flex;
}
#admin .navigation{
  flex: 1;
  transition: 0.5s ease all;
}
#admin .admin-side{
  flex: 4;
}

</style>