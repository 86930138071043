<template>
  <section id="stats">
      <div class="item">
          <div class="icon male">
              <img src="@/assets/icon/male_black_24dp.svg" alt="">
          </div>
          <div class="right">
              <div class="info">
                  <h3>Male</h3>
              </div>
              <div class="progress">
                  <svg viewBox="0 0 36 36" class="circular-chart orange">
                    <path class="circle-bg" d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831" />
                <path class="circle" stroke-dasharray="45, 100"
                    d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"/>
                <text x="18" y="20.35" class="percentage">45%</text>
                 </svg>
  
              </div>
              <h3>675</h3>
          </div>
      </div>
      <div class="item">
          <div class="icon female">
              <img src="@/assets/icon/female_black_24dp.svg" alt="">
          </div>
          <div class="right">
              <div class="info">
                  <h3>Female</h3>
              </div>
              <div class="progress">
                  <svg viewBox="0 0 36 36" class="circular-chart red">
                    <path class="circle-bg" d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831" />
                <path class="circle" stroke-dasharray="55, 100"
                    d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"/>
                <text x="18" y="20.35" class="percentage">55%</text>
                 </svg>
  
              </div>
              <h3>675</h3>
          </div>
      </div>
      
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
#stats{
    margin: 1rem 0;
}

#stats .item{
    background: #f7f7f7;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    padding: 0 0.9rem;
    border-radius: 10px;
    transition: all 300ms ease;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
}
#stats .item:hover{
    box-shadow: none;
}
#stats .item .right{
    display: flex;
    justify-content: space-between;
    align-items:center ;
    margin: 0;
    width: 100%;
}
#stats .item .right .progress{
    width: 70px;
}
.circular-chart {
  display: block;
  margin: 0.5rem auto;
  max-width: 80%;
}
.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}
.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
.circular-chart.orange .circle {
  stroke: var(--secondary);
}
.circular-chart.red .circle {
  stroke: var(--danger);
}
.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

#stats .item .icon{
    width: 50px;
    height: 50px;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;    
}
#stats .male{
    background: var(--footer);
}
#stats .male-perc{
    font-size: 1.3rem;
    color: var(--secondary);
    font-family: 'Dancing Script', cursive;
}
#stats .fe-perc{
    font-size: 1.3rem;
    color: var(--danger);
    font-family: 'Dancing Script', cursive;
}
#stats .item img{
    filter: invert(80%);
}
#stats .female{
    background: var(--danger);
}
#stats .item .right .info{
    min-width: 40px;
}
#stats .item .right h3{
    font-size: 0.9rem;
}

</style>