<template>
<Spinner v-show="loading"/>
<div class="blog-card-wrap">
  <div class="error" v-if="error">{{error}}</div>
      <div class="blog-cards container">
          <div class="toggle-edit">
              <span>Toggle Editing Post</span>
              <input type="checkbox" v-model="editPost">
          </div>
          <BlogCard :post="post" v-for="(post,id) in documents" :key="id"/>
      </div>
  </div>
</template>

<script>
import getCollection from "../composables/getCollection"
import BlogCard from "../components/BlogCard.vue"
import { computed} from '@vue/runtime-core';
import store from '../store';
import Spinner from "../components/Spinner.vue";
export default {
    name: "ViewBlogs",
    setup() {
      
        
        const { error, documents, loading} = getCollection("blogPosts");
       
        const editPost = computed(
            {
                get(){
                    return store.state.editPost
                },
                set(payload){
                    store.commit("toggleEditPost",payload)
                }
            }
        )
      return { error, documents, editPost, loading };
    },
    components: { BlogCard, Spinner }
}
</script>

<style scoped>
.blog-card-wrap{
  position: relative;
  padding: 80px 16px;
  background: #f1f1f1;
}
.blog-card-wrap .blog-cards{
 display: grid;
 grid-template-columns: auto auto auto auto;
 column-gap: 1rem;
 row-gap: 1rem;
}
.error{
  text-align: center;
  font-size: 12px;
  color: var(--danger);
}
.blog-cards{
    position: relative;
}
.blog-cards .toggle-edit{
    display: flex;
    align-items: center;
    position: absolute;
    top: -70px;
    right:0;

}
.blog-cards .toggle-edit span{
    margin-right: 16px;
}

.blog-cards .toggle-edit input[type="checkbox"]{
    position: relative;
    border: none;
    -webkit-appearance: none;
    background: #fff;
    outline: none;
    width: 80px;
    height: 25px;
    border-radius: 20px;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
}

.blog-cards .toggle-edit input[type="checkbox"]:before{
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 20px;
    top: 0;
    left:0;
    background: #303030;
    transform: scale(1.1);
    transition: 750ms ease all;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);

}

.blog-cards .toggle-edit input:checked[type="checkbox"]:before{
    background: var(--primary);
    left: 52px;
}
</style>