<template>
  <section id="admin">
    <div class="navigation"   v-if="showValue">
      <AdminNavigation/>
    </div>
    <div class="admin-side">
      <div class="head">
        <AdminHeader  @showBar="showBar"/>
      </div>
      <div class="body">
        <AddEvent/>
        <div class="container">
          <h3>Upcoming Events</h3>
          <ShowEvents :event="event" v-for="(event,id) in documents" :key="id"/>
        </div>
        
      </div>
    </div>
  </section>
</template>

<script>
import AdminNavigation from "../components/AdminNavigation.vue";
import AdminHeader from "../components/AdminHeader.vue";
import CreateEvent from "../components/CreateEvent.vue";
import AddEvent from "../components/AddEvent.vue";
import ShowEvents from "../components/ShowEvents.vue";
import getCollection from '../composables/getCollection';
import { ref } from '@vue/reactivity';
export default {
  name:'CreateEvent',
    components: { AdminNavigation, AdminHeader, CreateEvent, AddEvent, ShowEvents },
    setup(){
      const {documents} = getCollection('events')
      const showValue = ref(null)
      const showBar = (show)=>{
      showValue.value = show
      }

      return {documents,showBar,showValue}
    }
}
</script>

<style>
#admin{
  height: 100vh; 
  display: flex;
}
#admin .navigation{
  flex: 1;
}
#admin .admin-side{
  flex: 4;
}

</style>