<template>
  <section id="banner">
      <div class="container">
          <h1 class='section-heading'>Centre of Academic Excellence</h1>
           <p>Yangchenphug aspires to be the centre of academic excellence in providing Senior Secondary Education with a pleasant ambience and learning atmosphere conducive to the total personality development of the learners.</p>
            <div class="wrapper">
              <div class="box">
                  <img src="@/assets/icon/standard.svg" alt="">
                  <div class="content">
                      <h3>High standards and expectations</h3>
                      <p>We believe and work towards setting high standards for students while recognizing
the need and providing relevant help to overcome significant barriers.</p>
                  </div>
              </div>
              <div class="box">
                  <img src="@/assets/icon/collaboration.svg" alt="">
                  <div class="content">
                      <h3>High levels of collaboration and communication</h3>
                      <p>There is a strong teamwork among all staffs across all grades and subject areas.</p>
                  </div>
              </div>
              <div class="box">
                  <img src="@/assets/icon/environment.svg" alt="">
                  <div class="content">
                      <h3>Supportive learning environment</h3>
                      <p>The school has a safe, civil, healthy and intellectually stimulating learning environment.</p>
                  </div>
              </div>
            
              
              <div class="box">
                  <img src="@/assets/icon/leadership.svg" alt="">
                  <div class="content">
                      <h3>Effective school leadership</h3>
                      <p>The school is supported by effective instructional and administrative leadership</p>
                  </div>
              </div>
              
          </div>
      </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>

#banner .container h3{
    font-family: 'Rajdhani', sans-serif;
    font-size: 1rem;
    color: var(--secondary);
    
}
#banner .container .features{
    padding: 0.5rem 0;
}
#banner .container .wrapper .box{
    width: 100%;
    margin: 0.5rem 0;
    }
#banner .container .wrapper .box img{
    display: none;
}

@media (min-width:968px) {
    #banner .container .wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    
}
#banner .container .wrapper .box{
    display: flex;    
    align-items: center;
    width: 45%;
    margin: 1rem;
    font-size: 14px;
    color: var(--ternary);
}
#banner .container .wrapper .box img{
    display: block;
    padding-right: 1rem;
    width: 2.9rem;
    filter: invert(30%);
}
}
</style>