<template>
  <div class="subnav-wrapper">
      <div class="mega-menu">
          <div class="menu">
              <ul>
                  <li> <router-link :to = "{name: 'Background'}">Background <p>Know the history of our school</p></router-link></li>
                  <li><router-link :to = "{name: 'Principals'}"> List of Principals <p>Find out about our past principals</p></router-link></li>
                  <li><router-link :to = "{name: 'Committees'}">Committees <p>Committes in the school</p></router-link></li>
              </ul>
          </div>
          <div class="menu">
              <ul>
                  <li>
                      <router-link :to = "{name: 'VisionMission'}">Vision &amp; Mission<p>Read our vision and mission</p></router-link>
                  </li>
                  <li><router-link :to = "{name: 'Organogram'}">Organogram<p>Representation of our organisation's structure</p></router-link></li>
                  <li><router-link :to = "{name: 'SchoolTiming'}">School Timing<p>Find out about our official school timing</p></router-link></li>

              </ul>
          </div>
          
          
      </div>
  </div>
</template>

<script>

export default {
    
}
</script>

<style scoped>
.subnav-wrapper{    
    width: 480px;  
}
</style>

