<template>
<footer>
  <div class="container">
      <div class="box">
          <h3>Contact Us</h3>
          <ul>
              <li>Yangchenphug HSS</li>
              <li>Thimphu, Bhutan</li>
              <li>Post Box : 160</li>
               <li>Office : 02-322366</li>
              <li>Principal : +975-2-322166/77141433</li>
              
               
              
          </ul>
      </div>
      <div class="box">
      <h3>Quick Links</h3>
      <ul>
               <li><a href="https://result.yhss.edu.bt/" target="_blank">View Result</a></li>
              <li><router-link :to = "{name: 'Contact'}">Contact Us</router-link></li>
               <li><router-link :to = "{name:'PastPapers'}">Past Question Papers</router-link></li>
              <li><router-link :to = "{name: 'Downloads'}">Download Forms</router-link></li>
               <li><a href="https://drive.google.com/drive/folders/1Drbyh8YkFqWHo4jOzNWudzEkbtu6ttdX?usp=sharing" target="_blank">Picture Gallery</a></li>
              
          </ul>
        </div>
      <div class="box">
          <h3>Important Links</h3>
          <ul>
              <li><a href="https://www.ywfc.yhss.edu.bt/" target="_blank">Yangchenphug WFC</a> </li>
              <li><a href="https://bcsea.bt/" target="_blank">BCSEA</a> </li>
               <li><a href="https://max.rcsc.gov.bt/Login" target="_blank">RCSC Max</a> </li>
               <li><a href="https://rec.gov.bt/" target="_blank">Royal Education Council</a> </li>             
               <li><a href="http://www.education.gov.bt/" target="_blank">Ministry of Education</a> </li>
              
          </ul>
      </div>
     
      
  </div>
</footer>
<footer class="copyright">
    <div class="container">
        <div>Copyright © 2022. &nbsp;<span>Yangchenphug Higher Secondary School</span></div>
        <div><router-link :to = "{name: 'Contact'}">Contact Us</router-link> &nbsp; | &nbsp; <router-link :to = "{name: 'Contact'}">Sitemap </router-link> &nbsp;| &nbsp; <a href="https://www.facebook.com/kwitzpurr77">Contact &nbsp;<span>Developer</span></a></div>
    </div>
</footer>
</template>

<script>
export default {

}
</script>

<style scoped>
footer{
    background: url(../../assets/icon/nami.png), var(--footer);
    padding: 1rem 0;
}
footer .container{
    display: block;
    color: var(--pure);
    
  
}
footer .box{
    flex: 1;
    margin-right: 2rem;
    text-align: left;
    
}
footer ul,a{
    list-style-type: none;
    color: #afa7a7;
    text-decoration: none;
    font-size: 0.75rem;
    transition: 0.5s ease all;
}
footer a:hover{
    color: var(--primary);
}
footer .box h3{
    border-bottom: 1px solid var(--primary);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 100;
}
footer .box ul li{
    margin-bottom: 0.5rem;
}

.copyright{
    padding: 0.5rem 0;
    background: url(../../assets/icon/nami.png), var(--secondary);    
    color: #afa7a7;
    text-decoration: none;    
    font-size: 0.75rem;
}
.copyright .container{
    display: block;
    justify-content: space-between;
    font-size: 0.75rem;   
}
.copyright .container span{
    color: var(--primary);
}

@media (min-width:768px) {
    footer .container{
    display: flex;
    }
}
@media (min-width:800px) {
    .copyright .container{
    display: flex;
    }
}
/* @media (max-width:992px){
    footer .container{
        display: block;
    }
    .copyright .container{
        display: block;
    }
  } */
</style>