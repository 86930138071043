<template>
  <ais-instant-search
    index-name="blogPosts"
    :search-client="client"
  >
      
  </ais-instant-search>
</template>

<script>



import getCollection from '../composables/getCollection'
export default {
     setup(){

      
        const algoliasearch = require('algoliasearch')
        const client = algoliasearch('R7EB5ELOJO','b4a19b12cf58681a7e8273f9bc2d6194')
        const index = client.initIndex('blogPosts')
        

        const {documents} = getCollection('blogPosts')
        Array.from(documents).forEach((doc) => {
            const data = {
                title: doc.data().blogID,
                content: doc.data().blogHTML,
                date:doc.data().date
            }
            index.saveObject(data,{ autoGenerateObjectIDIfNotExist: true })
        });

        

        
        return {client}
    }
}
</script>

<style>

</style>