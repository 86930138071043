<template>
    <header class="topbar">
        <div class="container">            
            <div class ="icons"> 
                <a target="_blank" href="https://www.facebook.com/yangchenphughss"><img src="@/assets/icon/facebook-f-brands.svg" alt=""></a>
                <a target="_blank" href="https://www.twitter.com/yangchenphughss"><img src="@/assets/icon/twitter-brands.svg" alt=""></a>
                <a target="_blank" href="https://www.youtube.com/yangchenphughss"><img src="@/assets/icon/youtube-brands.svg" alt=""></a>
                <a target="_blank" href="https://www.instagram.com/yangchenphughss/"><img src="@/assets/icon/instagram-brands.svg" alt=""></a>
            </div>
            <div class="info">
                <div>                    
                    <router-link :to = "{name:'Contact'}">Contact Us</router-link>
                </div>
                <span class="divider">|</span>
                <div>                  
                    <a target="_blank" href="mailto:yangchenphughss@education.gov.bt">Email Us</a>
                </div>
                <span class="divider">|</span>
                <div>
                   
                    <router-link :to="{name:'Login'}" target="_blank">Admin Login</router-link>
                </div>
            </div>
        </div>
        
        
    </header>  
</template>

<script>
export default {
    name: 'Home',
}
</script>

<style>

header.topbar{
    width: 100%;
    background: var(--secondary);
    color: #afa7a7;
    padding: 0.35rem 0;
}
header.topbar .icons a{
    margin-right: 0.6rem;
    color: #afa7a7;
    text-decoration: none;
}
header.topbar .icons{
    display: none;
}
header.topbar .icons img{
    height: 15px;
    filter: invert(80%);
    transition: .5s ease all;
}
header.topbar .icons img:hover{
    filter: invert(60%);
}
header.topbar .info .divider{
    padding: 0 1rem;
}
header.topbar .info >div a{
    color: #afa7a7;
    text-decoration: none;    
    font-size: 0.75rem;
    transition: .5s ease all;
}
header.topbar .info >div a:hover{
    color: var(--primary);
}
header.topbar .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
header.topbar .container .info{
    display: flex;
    align-items: center;
    
}
@media (min-width:600px){
    header.topbar .icons{
        display: block;
    }
}
@media (max-width:992px){
   header.topbar {
        width: 100%;
        
    } 
    
}

</style>