<template>
  <div class="subnav-wrapper">
      <div class="mega-menu">
          <div class="menu">
              <ul>
                  <li> <router-link :to = "{name: 'SchoolManagement'}">School Management<p>Know our School Administration</p></router-link></li>
                  <li><router-link :to = "{name: 'TeachingStaffs'}">Teaching Staffs<p>Know our teaching staffs</p></router-link></li>
                  <li><router-link :to = "{name: 'NonTeachingStaffs'}"> Non-teaching Staffs<p>Know our non-teaching and supporting staffs</p></router-link></li>
                  
              </ul>
          </div>
          
          
          
      </div>
  </div>
</template>

<script>
export default {
    
    
}
</script>

<style scoped>
.subnav-wrapper{
    
    width: 300px;    
    left: -50px;
    
 
}

</style>

