<template>
    <aside v-if="documents">
        <HomeAnnCard :ann="ann" v-for="(ann,id) in documents.slice(0,5)" :key="id"/>
    </aside>
    <div v-else>
        <Loading/>
    </div>
</template>

<script>
import getCollection from '../../composables/getCollection'
import HomeAnnCard from "./HomeAnnCard.vue"
import Spinner from '../Spinner.vue'
import Loading from '../Loading.vue'

export default {
    setup(){
        const {documents} = getCollection('announcements')

        return {documents}
    },
    components: { HomeAnnCard, Spinner, Loading }
}
</script>

<style>

</style>