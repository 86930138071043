<template>
  <nav id="admin-navigation">
      <div class="logo">
          <img src="../assets/img/logo.png" alt="YHSS logo">
          <h2>Admin</h2>
      </div>
      <div class="items">
          <li class="heading"><router-link :to="{name:'AdminPage'}"><img src="../assets/icon/chart-pie-solid.svg" alt="">Dashboard</router-link></li>
          <li class="heading"><router-link :to="{name:'Profile'}"><img src="../assets/icon/user-alt-light.svg" alt="">Profile</router-link></li>
          <li class="heading" @click="create=!create"><router-link to="#"><img src="../assets/icon/plus-solid.svg" alt="">Create</router-link></li>
                <li class="subheading" v-if="create"><router-link :to="{name:'CreatePost'}"><img src="../assets/icon/signs-post-solid.svg" alt="">Post</router-link></li>
                <li class="subheading" v-if="create"><router-link :to="{name:'CreateEvent'}"><img src="../assets/icon/calendar-solid.svg" alt="">Event</router-link></li>
                <li class="subheading" v-if="create"><router-link :to="{name:'CreateAnnouncement'}"><img src="../assets/icon/bell-solid.svg" alt="">Announcement</router-link></li> 
          <li class="heading" @click="view=!view"><router-link to="#"><img src="../assets/icon/eye-solid.svg" alt="">View</router-link></li>
                <li class="subheading" v-if="view"><router-link :to="{name:'Blog'}"><img src="../assets/icon/signs-post-solid.svg" alt="">Posts</router-link></li>
                <li class="subheading" v-if="view"><router-link :to="{name:'CreateEvent'}"><img src="../assets/icon/calendar-solid.svg" alt="">Events</router-link></li>
                <li class="subheading" v-if="view"><router-link :to="{name:'Announcements'}"><img src="../assets/icon/bell-solid.svg" alt="">Announcements</router-link></li> 
          <li><router-link to="#"><img src="../assets/icon/users-solid.svg" alt="">Users</router-link></li>
          
      </div>
  </nav>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
    name:"AdminNavigation",
    setup(){
        const create = ref(false)
        const view = ref(false)

        return {create,view}
    }
}
</script>

<style scoped>
#admin-navigation{
    background: #111827;    
    height: 100vh;
    
    
}
#admin-navigation .logo{
    display: flex;
    align-items: center;
    padding: 30px 0 30px 30px;
    border-bottom: 1px solid rgb(43, 35, 35);
}
#admin-navigation .logo img{
    width: 80px;
    margin-right: 1rem;
}
#admin-navigation .logo h2{
    font-family: 'Cookie', cursive;
    font-size: 2rem;
    font-weight: 400;
    color: rgba(134,141,151);
}
#admin-navigation .items{
    margin-top: 40px;
}
#admin-navigation .items li{
    list-style-type: none;
    padding: 0.4rem;
    color: #f1f1f1;
    display: flex;
    padding-left: 2rem;
    transition: all 300ms ease;
    align-items: center;
}
#admin-navigation .items img{
    width: 12px;
    filter: invert(60%);
    margin-right: 1rem;
    
}
#admin-navigation .items li a{
    text-decoration: none;
    color: rgba(134,141,151);
    transition: all 0.5s ease;
}
#admin-navigation .items .subheading{
    margin-left: 3.8rem;
}
#admin-navigation .items li:hover{
    background: #253047;
    cursor: pointer;
    
  
}
#admin-navigation .items li a:hover{
    margin-left: 0.5rem;
}
#admin-navigation .items li a:hover{
    color: #f3f4f6;
}
</style>