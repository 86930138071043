import { ref} from '@vue/reactivity'
import { projectFirestore } from '../firebase/config'
import { watchEffect } from '@vue/runtime-core'

const getCollection = (collection)=>{
    const documents = ref(null)
    const error = ref(null)
    const loading = ref(null)
   
    let collectionRef = projectFirestore.collection(collection).orderBy('date','desc')
    loading.value = true
    const unsub = collectionRef.onSnapshot((snap)=>{
        let results = []
        snap.docs.forEach(doc=>{
            doc.data().date && results.push({...doc.data(),id:doc.id})
        })
        documents.value = results
        loading.value = false
        error.value = null
    },(err)=>{        
        documents.value = null
        error.value = "Could not fetch the document"
    })

    watchEffect((onInvalidate)=>{
        //unsub from prev collection when watcher is topped
        onInvalidate(()=>unsub())
    })
    return {documents, error, loading}
}

export default getCollection