<template>
  <section id="admin">    
      <div class="navigation"   v-if="showValue">
        <AdminNavigation/>
    </div>
   
    <div class="admin-side">
      <div class="head">
        <AdminHeader  @showBar="showBar"/>
      </div>
      <div class="body">
        <div class="container">
          <Dashboard/>       

        </div>
      </div>  
    </div>
  </section>
</template>

<script>
import AdminNavigation from "../components/AdminNavigation.vue";
import AdminHeader from "../components/AdminHeader.vue";
import { ref } from '@vue/reactivity';
import Dashboard from '../components/Dashboard.vue';
export default {
  name:'AdminPage',
  
    components: { AdminNavigation, AdminHeader, Dashboard },
    setup(){
      const showValue = ref(null)
      
      const showBar = (show)=>{
        showValue.value = show
      }
      return {
        showBar, showValue
      }
    }
    
}
</script>

<style scoped>
#admin{
  height: 100vh; 
  display: flex;
  
}
#admin .navigation{
  flex: 1;
  transition: all 300ms ease;
}
#admin .admin-side{
  flex: 4;
}
#admin .admin-side .body h1{
  text-align: center;
  padding-top: 1rem;
}

</style>