<template>

  <div class="blog-card" v-if="post">
            
            <div class="cardImg">
                <img :src="post.blogCoverPhoto" >
            </div>
           <div class="info">
               <h4>{{post.blogTitle}}</h4>
               <h6>Posted on: {{post.date.toDate().toLocaleString('eng-us',{dateStyle:"long"})}}</h6>
               <p v-html="snippet"></p>
               <router-link class="link" :to="{name:'ViewBlog', params: {id:post.id} }">
                   View the Post <img src="@/assets/icon/arrow-right-light.svg" class="arrow" alt="">
               </router-link>
           </div>    
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
export default {
    name: "blogCard",
    props: ["post"],
    
    setup(props) {         
        const snippet = computed(() => {
            return props.post.blogHTML.substring(0, 100) + "...";
        });
        return { snippet };
    },
  
}
</script>

<style scoped>
.blog-card{
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #fff;
    min-height: 400px;
    transition: .5s ease all;
   
    
}
.blog-card:hover{
    transform: rotateZ(-1deg) scale(1.01);
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
}

.blog-card .cardImg img{
    height: 50px;
    width: 100px;
}


.blog-card .cardImg img{
    display: block;
    border-radius: 8px 8px 0 0;
    z-index: 1;
    width: 100%;
    min-height: 150px;
    object-fit: cover;
   
}
.blog-card .info{
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 3;
    padding: 32px 13px;
    columns: #000;
}
.blog-card .info h4{
    padding-bottom: 8px;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--secondary);
    font-family: 'Rajdhani', sans-serif;
}
.blog-card .info h6{
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 16px;
}
.blog-card .info .link{
    display: inline-flex;
    align-items: center;
    margin-top: auto;
    font-weight: 500;
    padding-top: 20px;
    font-size: 12px;
    padding-bottom: 4px;
    text-decoration: none;
    transition: 0.5s ease-in all;
    color: var(--secondary);
}
.blog-card .info .link:hover{
    color: var(--primary);
}
.blog-card .info .link .arrow{
    margin-left: 1rem;
    width: 10px;
}

</style>