import { createStore } from 'vuex'
import { projectFirestore } from '../firebase/config';

export default createStore({
  state: {
   blogPosts:[],
   postLoaded:null,
   editPost: null,
   editAnn: null,
   blogHTML: "",
   blogTitle: "",
   blogPhotoName:"",
   blogPhotoFileURL: null,
   blogPhotoPreview: null,
   profileId: 'admin',
   announcements:[],
   annTitle: "",
   annHTML:"",
  },
  mutations: {
    newBlogPost(state,payload){
      state.blogHTML = payload;
    },
    updateBlogTitle(state,payload){
      state.blogTitle = payload;
    },
    fileNameChange(state,payload){
      state.blogPhotoName = payload
    },
    createFileURL(state,payload){
      state.blogPhotoFileURL = payload
    },
    openPhotoPreview(state){
      state.blogPhotoPreview = !state.blogPhotoPreview
    },
    toggleEditPost(state,payload){
      state.editPost = payload;
    },
    toggleEditAnn(state,payload){
      state.editAnn = payload;
    },
    filterBlogPost(state,payload){
      state.blogPosts = state.blogPosts.filter((post)=>post.blogID !==payload)
    },
    filterAnn(state,payload){
      state.announcements = state.announcements.filter((ann)=>ann.annID !==payload)
    },
    setBlogState(state, payload){    
      state.blogTitle = payload.blogTitle
      state.blogHTML = payload.blogHTML
      state.blogPhotoFileURL = payload.blogCoverPhoto
      state.blogPhotoName = payload.blogCoverPhotoName        
    },
    newAnnHTML(state,payload){
      state.annHTML = payload;
    },
    updateAnnTitle(state,payload){
      state.annTitle = payload;
    },
    setAnnState(state, payload){    
      state.annTitle = payload.annTitle
      state.annHTML = payload.annHTML     
    },
  },
  actions: {
   async getPost({state}){
     const dataBase = await projectFirestore.collection('blogPosts').orderBy("date","desc")
     const dbResults = await dataBase.get()
     dbResults.forEach((doc)=>{
       if(!state.blogPosts.some(post=> post.blogID === doc.id)){
         const data = {
           blogID: doc.data().blogID,
           blogHTML: doc.data().blogHTML,
           blogCoverPhoto: doc.data().blogCoverPhoto,
           blogTitle: doc.data().blogTitle,
           blogDate: doc.data().date,
           blogCoverPhotoName: doc.data().blogCoverPhotoName,
         };
         state.blogPosts.push(data);
         
         
       }
     });
     state.postLoaded = true
     
   },
   async updatePost({commit,dispatch},payload){
     commit("filterBlogPost", payload)
     await dispatch('getPost')
   },
   async getAnn({state}){
    const dataBase = await projectFirestore.collection('announcements').orderBy("date","desc")
    const dbResults = await dataBase.get()
    dbResults.forEach((doc)=>{
      if(!state.announcements.some(ann=> ann.annID === doc.id)){
        const data = {
          annID: doc.data().annID,
          annHTML: doc.data().annHTML,
          annTitle: doc.data().annTitle,
          date: doc.data().date,
        };
        state.announcements.push(data);
        
        
      }
    });
    state.postLoaded = true   
  },
  async updateAnn({commit,dispatch},payload){
    commit("filterAnn", payload)
    await dispatch('getAnn')
  },
  },
  modules: {
  }
})
