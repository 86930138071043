<template>
  <section id="login">
      <div class="login-wrapper">
          <div class="left">
              <div>
                <img src="../assets/img/logo.png" alt="Yangchenphug HSS">
                <h1>Yangchenphug HSS</h1>
              </div>
             <div>
                <router-link :to="{name:'Home'}"><h3>Home</h3>
                <img src="../assets/icon/arrow-right-light.svg" alt="">
                </router-link>
             </div>
          </div>
          <div class="right">
              <form @submit.prevent="handleSubmit">
                  <h2>Reset Password</h2>
                  <p>Forgot your password? Enter your email to reset it</p>
                  <div class="inputs">
                      <div class="input">
                        <input type="email" placeholder="Email" v-model="email" required>
                        <img src="../assets/icon/envelope-regular.svg" alt="" class="icon">
                      </div>
                      
                    
                  </div>
                   <div class="error">{{error}}</div>
                   <div class="error">{{message}}</div>
                  <router-link :to="{name:'Login'}" class="forgot-password">Back to Login</router-link>
                 <button>Reset Password</button>
              </form>
          </div>
      </div>
  </section>
</template>

<script>
import { ref } from '@vue/reactivity'
import useForgotPassword from '../composables/useForgotPassword'
export default {
  name:"forgotPassword",
  setup(){
    const email = ref("")
    const message = ref(null)
    const {error, forgotPassword} = useForgotPassword()

    const handleSubmit = async()=>{
      await forgotPassword(email.value)
      if (!error.value){
       message.value = "The password reset link has been mailed to you."
       email.value = ""
      }
    }
    
    return {error, email, handleSubmit,message}
  }
}
</script>

<style>
</style>