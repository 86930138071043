<template>
<div class="error" v-if="error">{{error}}</div>
  <div class="blog-card">
            <div class="icons" v-show="editPost">
                <div @click="handleEdit" class="icon">
                    <img src="../assets/icon/edit-regular.svg" alt="" class="edit">
                </div>
                <div  @click="handleDelete" class="icon" >
                    <img src="../assets/icon/trash-regular.svg" alt="" class="delete">
                </div>
            </div>
            <div class="cardImg">
                <img :src="post.blogCoverPhoto" >
            </div>
           <div class="info">
               <h4>{{post.blogTitle}}</h4>
               <h6>Posted on: {{post.date.toDate().toLocaleString('eng-us',{dateStyle:"long"})}}</h6>
               <p v-html="snippet"></p>
               <router-link class="link" :to="{name:'ViewPost', params: {id:post.id} }">
                   View the Post <img src="../assets/icon/arrow-right-light.svg" class="arrow" alt="">
               </router-link>
           </div>    
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import store from '../store'
import useDocument from '../composables/useDocument';
import { projectStorage } from '../firebase/config';
import { useRouter } from 'vue-router';


export default {
    name: "blogCard",
    props: ["post"],
    
    setup(props) {     
        const router = useRouter()
        const {error, deleteDoc} = useDocument('blogPosts', props.post.id)
        // Delete image from storage
        const deleteImage = (path)=>{
            let pictureRef = projectStorage.refFromURL(path)
            pictureRef.delete()
        }
        
        //Delete post and image
        const handleDelete = async()=>{            
            await deleteImage(props.post.blogCoverPhoto)
            await deleteDoc()
        }
        const handleEdit = ()=>{
            router.push({name:'EditPost', params: {id: props.post.id}})
        }
        const editPost = computed(() => {
            return store.state.editPost;
        });
        const snippet = computed(() => {
            return props.post.blogHTML.substring(0, 100) + "...";
        });
        return { editPost, snippet, handleDelete,error,handleEdit };
    },
  
}
</script>

<style scoped>
.blog-card{
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #fff;
    min-height: 400px;
    transition: .5s ease all;
    
}
.blog-card:hover{
    transform: rotateZ(-1deg) scale(1.01);
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
}
.blog-card .icons{
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;
}
.blog-card .icons .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #fff;
    transition: 0.5s ease all;
}
.blog-card .cardImg img{
    height: 50px;
    width: 100px;
}

.blog-card .icons .icon:hover{
    background: #303030;

}
.blog-card .icons .icon .edit, .blog-card .icons .icon .delete{
    fill: #fff;
    pointer-events: none;
    height: 15px;
    width: auto;
}
.blog-card .icons .icon:nth-child(1){
    margin-right: 8px;
}
.blog-card .cardImg img{
    display: block;
    border-radius: 8px 8px 0 0;
    z-index: 1;
    width: 100%;
    min-height: 150px;
    object-fit: cover;
   
}
.blog-card .info{
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 3;
    padding: 32px 13px;
    columns: #000;
}
.blog-card .info h4{
    padding-bottom: 8px;
    font-size: 20px;
    font-weight: 300;
}
.blog-card .info h6{
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 16px;
}
.blog-card .info .link{
    display: inline-flex;
    align-items: center;
    margin-top: auto;
    font-weight: 500;
    padding-top: 20px;
    font-size: 12px;
    padding-bottom: 4px;
    text-decoration: none;
    transition: 0.5s ease-in all;
    color: var(--secondary);
}
.blog-card .info .link:hover{
    color: var(--primary);
}
.blog-card .info .link .arrow{
    margin-left: 1rem;
    width: 10px;
}

</style>