import { projectFirestore } from "../firebase/config"
const { ref } = require("vue")
import { watchEffect } from '@vue/runtime-core'

const getAnn = (id)=>{
        const ann = ref(null)
        const error = ref(null)
             
            let documentRef = projectFirestore.collection('announcements').doc(id)

            const unsub = documentRef.onSnapshot(doc=>{
                if (doc.data()){
                    ann.value = {...doc.data(), id:doc.id}
                    error.value = null
                }else{
                    error.value = "The document does not exist"
                }
                },(err)=>{
                   
                    error.value = "Could not fetch data"
                })
            
                watchEffect((onInvalidate)=>{
                    //unsub from prev collection when watcher is topped
                    onInvalidate(()=>unsub());
                })
        
        return {ann, error}
}

export default getAnn