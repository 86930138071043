import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import AdminPage from '../views/AdminPage.vue'
import Profile from '../views/Profile.vue'
import CreatePost from '../views/CreatePost.vue'
import Blog from '../views/Blog.vue'
import PostPreview from '../views/BlogPreview.vue'
import ViewPost from '../views/ViewPost.vue'
import EditPost from '../views/EditPost.vue'

//Events
import CreateEvent from '../views/CreateEvent.vue'

//Announcement
import CreateAnnouncement from '../views/CreateAnnouncement.vue'
import ViewAnnouncement from '../views/ViewAnnouncement.vue'
import AnnPreview from '../views/AnnPreview.vue'
import Announcements from '../views/Announcements.vue'
import EditAnn from '../views/EditAnn.vue'


import { projectAuth } from '../firebase/config'

// auth guard
const requireAuth = (to,from,next) =>{
  let user = projectAuth.currentUser
  if (!user){
    next({name:'Login'})
  }else{
    next()
  }
}

function lazyLoad(view){
  return() => import(`@/views/pages/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Perseverance for Excellence'
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login'
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'Reset Password'
    },
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: AdminPage,
    beforeEnter: requireAuth,
    meta: {
      title: 'Admin'
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: requireAuth,
    meta: {
      title: 'Profile'
    },
  },
  {
    path: '/create-post',
    name: 'CreatePost',
    component: CreatePost,
    beforeEnter: requireAuth,
    meta: {
      title: 'Create Post'
    },
  },
  {
    path: '/edit-post/:id',
    name: 'EditPost',
    component: EditPost,
    props:true,
    beforeEnter: requireAuth,
    meta: {
      title: 'Edit Post'
    },
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
    beforeEnter: requireAuth,
    meta: {
      title: 'Posts'
    },
  },
  {
    path: '/blog/:id',
    name: 'ViewPost',
    component: ViewPost,
    beforeEnter: requireAuth,
    props:true,
    meta: {
      title: 'Post'
    },
  },
  {
    path: '/posts-preview',
    name: 'PostPreview',
    component: PostPreview,
    beforeEnter: requireAuth,
    meta: {
      title: 'Preview'
    },
  },
  {
    path: '/event',
    name: 'CreateEvent',
    component: CreateEvent,
    beforeEnter: requireAuth,
    meta: {
      title: 'Events'
    },
  },
  {
    path: '/create-announcement',
    name: 'CreateAnnouncement',
    component: CreateAnnouncement,
    beforeEnter: requireAuth,
    meta: {
      title: 'Create Announcement'
    },
  },
  {
    path: '/admin-announcements/:id',
    name: 'ViewAnnouncement',
    props: true,
    component: ViewAnnouncement,
    beforeEnter: requireAuth,
    meta: {
      title: 'Announcement'
    },
  },
  {
    path: '/announcement-preview',
    name: 'AnnPreview',
    component: AnnPreview,
    beforeEnter: requireAuth,
    meta: {
      title: 'Preview Announcement'
    },
    
  },
  {
    path: '/admin-announcements',
    name: 'Announcements',
    component: Announcements,
    beforeEnter: requireAuth,
    meta: {
      title: 'Announcements'
    },
  },
  {
    path: '/edit-announcement/:id',
    name: 'EditAnn',
    component: EditAnn,
    props:true,
    beforeEnter: requireAuth,
    meta: {
      title: 'Edit Announcement'
    },
  },
  


  // Page routes
  {
    path: '/contact-us',
    name: 'Contact',
    component: lazyLoad('Contact'),
    meta:{
      title: "Contact Us"
    }    
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: lazyLoad('AboutUs'),
    meta:{
      title: "About Us"
    }    
  },
  {
    path: '/downloads',
    name: 'Downloads',
    component: lazyLoad("Downloads"),
    meta:{
      title: "Downloads"
    }
  },
  {
    path: '/past-question-papers',
    name: 'PastPapers',
    component: lazyLoad("PastPapers"),
    meta:{
      title: "Past Question Papers"
    }
  },
  {
    path: '/about-us/background',
    name: 'Background',
    component: lazyLoad("Background"),
    meta:{
      title: "Background of the school"
    }
  },
  {
    path: '/about-us/list-of-principals',
    name: 'Principals',
    component: lazyLoad("Principals"),
    meta:{
      title: "List of Principals"
    }
  },
  {
    path: '/about-us/committees',
    name: 'Committees',
    component: lazyLoad("Committees"),
    meta:{
      title: "Committees"
    }
  },
  {
    path: '/about-us/vision-and-mission',
    name: 'VisionMission',
    component: lazyLoad("VisionMission"),
    meta:{
      title: "Vision & Mission of the school"
    }
  },
  {
    path: '/about-us/organogram',
    name: 'Organogram',
    component: lazyLoad("Organogram"),
    meta:{
      title: "Organogram"
    }
  },
  {
    path: '/about-us/school-timing',
    name: 'SchoolTiming',
    component: lazyLoad("SchoolTiming"),
    meta:{
      title: "School Timing"
    }
  },
  {
    path: '/academics/departments',
    name: 'Department',
    component: lazyLoad("Department"),
    meta:{
      title: "Departments and HODs"
    }
  },
  {
    path: '/academics/publications',
    name: 'Publications',
    component: lazyLoad("Publications"),
    meta:{
      title: "Publications"
    }
  },
  {
    path: '/academics/guidance-and-counselling',
    name: 'Counselling',
    component: lazyLoad("Counselling"),
    meta:{
      title: "Guidance & Counselling"
    }
  },
  {
    path: '/academics/streams-offered',
    name: 'Courses',
    component: lazyLoad("Courses"),
    meta:{
      title: "Stream Offered"
    }
  },
  {
    path: '/academics/literary-activities',
    name: 'Literary',
    component: lazyLoad("Literary"),
    meta:{
      title: "Literary Activities"
    }
  },
  {
    path: '/academics/class-teachers',
    name: 'ClassTeachers',
    component: lazyLoad("ClassTeachers"),
    meta:{
      title: "Class Teachers"
    }
  },
  {
    path: '/academics/toppers',
    name: 'Toppers',
    component: lazyLoad("Toppers"),
    meta:{
      title: "Academic Toppers"
    }
  },
  {
    path: '/non-academics/games-and-sports',
    name: 'GamesSports',
    component: lazyLoad("GamesSports"),
    meta:{
      title: "Games & Sports"
    }
  },
  {
    path: '/non-academics/club-activities',
    name: 'Clubs',
    component: lazyLoad("Clubs"),
    meta:{
      title: "Club Activities"
    }
  },
  {
    path: '/staffs/school-management',
    name: 'SchoolManagement',
    component: lazyLoad("SchoolManagement"),
    meta:{
      title: "School Management"
    }
  },
  {
    path: '/staffs/teaching-staffs',
    name: 'TeachingStaffs',
    component: lazyLoad("TeachingStaffs"),
    meta:{
      title: "Teaching Staffs"
    }
  },
  {
    path: '/staffs/non-teaching-staffs',
    name: 'NonTeachingStaffs',
    component: lazyLoad("NonTeachingStaffs"),
    meta:{
      title: "Non-Teaching Staffs"
    }
  },
  {
    path: '/post/:id',
    name: 'ViewBlog',
    component: lazyLoad("ViewBlog"),
    props: true,
    meta:{
      title: "News and Events"
    }

  },
  {
    path: '/blogs',
    name: 'HomeBlogs',
    component: lazyLoad("Blogs"),
    meta:{
      title: "Blogs"
    }
  },
  {
    path: '/announcements',
    name: 'HomeAnn',
    component: lazyLoad("HomeAnn"),
    meta:{
      title: "Announcements"
    }
  },
  {
    path: '/announcement/:id',
    name: 'HomeViewAnnouncement',
    component: lazyLoad("HomeViewAnn"),
    props: true,
    meta:{
      title: "Announcements"
    }
  },
  {
    path: '/events',
    name: 'HomeEvents',
    component: lazyLoad("HomeEvents"),
    meta:{
      title: "Events"
    }
  },
  
  
]

const router = createRouter({
  
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})


router.beforeEach((to,from,next)=>{
  document.title = `${to.meta.title} | Yangchenphug Higher Secondary School`
  next()
})
export default router
