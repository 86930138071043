import { projectFirestore } from "../firebase/config"
const { ref } = require("vue")
import { watchEffect } from '@vue/runtime-core'

const getPost = (id)=>{
        const post = ref(null)
        const error = ref(null)
        
            let documentRef = projectFirestore.collection('blogPosts').doc(id)
            
            const unsub = documentRef.onSnapshot(doc=>{
                
                if (doc.data()){
                    
                    post.value = {...doc.data(), id:doc.id}
                    error.value = null
                    
                    
                }else{
                    error.value = "The post does not exist"
                }
                },(err)=>{
                    
                    error.value = "Could not fetch data"
                })
            
                watchEffect((onInvalidate)=>{
                    //unsub from prev collection when watcher is topped
                    onInvalidate(()=>unsub());
                })
        
        return {post, error}
}

export default getPost