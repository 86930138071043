<template>
  <div class="event" v-if="documents">
     <HomeEventCard :eve="eve" v-for="(eve,id) in documents.slice(0,5).reverse()" :key="id"/>
  </div>
  <div v-else>
      <Loading/>
  </div>
</template>

<script>
import getCollection from '@/composables/getCollection';
import Loading from '../Loading.vue';
import HomeEventCard from './HomeEventCard.vue';
export default {
    components: { Loading, HomeEventCard },
    setup(){
        const {documents} = getCollection('events')

        return {documents}
    }
}
</script>

<style scoped>

</style>