<template>
  <div class="info">
            <router-link :to="{name:'ViewAnnouncement', params: {id:ann.id} }">
               <h4>{{ann.annTitle}}</h4>
            </router-link>           
           </div> 
</template>

<script>
export default {
    props: ["ann"],
}
</script>

<style scoped>

.info a{
    text-decoration: none;
    color: #303030;
    font-size: 0.8rem;

}
</style>
